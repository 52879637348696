import Svg, { Defs, G, Mask, Path, SvgProps, Use } from "react-native-svg";

export function dDOGE(props: SvgProps): JSX.Element {
  return (
    <Svg height={32} width={32} viewBox="0 0 32 32" {...props}>
      <Defs>
        <Path
          d="M16 0C7.163 0 0 7.163 0 16s7.163 16 16 16 16-7.163 16-16S24.837 0 16 0z"
          id="dDOGE_svg__a"
        />

        <Path d="M0 .217h1v.566H0z" id="dDOGE_svg__c" />

        <Path d="M0 0h22.745v11.504H0z" id="dDOGE_svg__e" />

        <Path d="M0 0h1.433v4.492H0z" id="dDOGE_svg__g" />

        <Path d="M.013 0h.975v1H.013z" id="dDOGE_svg__i" />

        <Path d="M.018 0h.964v1H.018z" id="dDOGE_svg__k" />

        <Path d="M0 0h4.597v7.792H0z" id="dDOGE_svg__m" />

        <Path d="M0 .001h1v.998H0z" id="dDOGE_svg__o" />
      </Defs>

      <G fill="none" fillRule="evenodd">
        <Path
          d="M16 0C7.163 0 0 7.163 0 16s7.163 16 16 16 16-7.163 16-16S24.837 0 16 0z"
          fill="#F3EDD6"
          fillRule="nonzero"
        />

        <Mask fill="#fff" id="dDOGE_svg__b">
          <Use xlinkHref="#dDOGE_svg__a" />
        </Mask>

        <G mask="url(#dDOGE_svg__b)">
          <Path
            d="M24.078 30.003c-1.512 1.045-3.19 1.647-5.008 1.876-.148.02-.321-.044-.43.122h-1.178a.634.634 0 00-.59 0h-3.305c-.612-.322-1.315-.251-1.955-.46-.46-.148-.938-.234-1.37-.466.03-.13.128-.227.24-.226.669.003 1.263-.44 1.942-.333.365.057.386-.244.53-.43.222-.283.423-.664.858-.264-.003.05-.058.126.059.124.44-.38.962-.666 1.278-1.176.114-.12.26-.197.378-.31.655-.557 1.516-.601 2.272-.908.217-.088.449-.112.599-.328.107-.153.288-.191.463-.235 1.072-.266 1.892-.837 2.62-1.713.665-.797 1.586-1.435 2.789-1.194.459.092.705.577.689 1.205-.022.819-.356 1.513-.77 2.182-.16.26-.134.5-.042.76-.134.303-.435.325-.697.412-.415.03-.57.287-.583.669l.01.152c.013.147-.123.35.152.396l.218.07c.394.426.09-.345.325-.19l.152-.015c.127.08.27.143.354.28"
            fill="#E7CF90"
            fillRule="nonzero"
          />

          <G transform="translate(16.667 31.218)">
            <Mask fill="#fff" id="dDOGE_svg__d">
              <Use xlinkHref="#dDOGE_svg__c" />
            </Mask>

            <Path
              d="M.205.783c.196-.175.394-.177.59 0h-.59z"
              fill="#EBD8A4"
              fillRule="nonzero"
              mask="url(#dDOGE_svg__d)"
            />
          </G>

          <G transform="translate(4.5 19.57)">
            <Mask fill="#fff" id="dDOGE_svg__f">
              <Use xlinkHref="#dDOGE_svg__e" />
            </Mask>

            <Path
              d="M9.187 10.308c-.304-.123-.425-.117-.56.197-.198.46-.389.639-.983.587-.617-.055-1.266.26-1.901.412-1.885-.612-3.461-1.731-4.936-3.015-.133-.117-.24-.27-.387-.362-.542-.339-.492-.725-.228-1.244.322-.63.599-1.295.404-2.054-.045-.176.137-.4.163-.608.055-.453.097-.901.085-1.369-.014-.499.355-1.01.558-1.515.418.075.481.428.527.742.189 1.299.883 2.305 1.854 3.112.676.561 1.193 1.245 1.709 1.928.299.396.409.929.474 1.429.043.336-.013.75.357.85.345.093.746-.033 1.04-.287.704-.609 1.121-1.397 1.49-2.246.29-.666.832-1.163 1.475-1.574.567-.362 1.171-.681 1.691-1.118l.148.011c.114.087.229.174.383.077.281-.189.611-.257.922-.373.13-.049.298-.106.395-.02.34.305.757.209 1.145.28.219.04.565-.101.597.368.011.149.323-.06.501-.048.263.09.548-.008.811.09.202.136.332-.049.489-.111.29-.131.462-.422.75-.558.355-.124.652-.366 1.042-.438.281-.052.49-.295.526-.622a.515.515 0 01.274-.303c.602-.056.873-.53 1.105-.974.205-.392.45-.776.575-1.21.054-.173.16-.296.34-.342.417.084.235.557.485.75l.238.92c-.132.269.07.618-.206.852l-.046.235c-.107.039-.23.049-.267.195-.197.748-.536 1.459-.576 2.248-.083.141-.294.26-.075.452.164.423.112.765-.356.951l-.153.161c-.13.386-.387.675-.707.915-.172.075-.268.223-.357.378-.2.159-.075.552-.43.599-.449-.546.017-.983.261-1.344.518-.767.522-1.552.343-2.386-.041-.19-.198-.26-.397-.288-.836-.116-1.486.223-2.159.691-.607.421-.836 1.189-1.425 1.506-.327.177-.687.388-1.054.54-.466.193-.985.164-1.418.589-.344.338-.974.425-1.496.527-.44.085-.786.308-1.151.519-.163.011-.344-.005-.356.236-.272.648-.882.928-1.414 1.293-.068-.063-.195-.095-.119-.231"
              fill="#E2C170"
              fillRule="nonzero"
              mask="url(#dDOGE_svg__f)"
            />
          </G>

          <Path
            d="M16.634 23.738c-.112.3-.29.45-.64.53-.545.124-.822.74-1.37.905-.554.166-.558.887-1.1 1.059l-.066.157c-.157 1.017-.815 1.726-1.49 2.42-.304.313-.768.234-1.134.422-.179.092-.546-.605-.545-.94.002-.635-.217-1.198-.562-1.737-.656-1.026-1.724-1.661-2.439-2.628-.502-.678-.927-1.383-.984-2.258-.019-.303-.194-.546-.403-.761l-.004-.24c-.079-.136-.115-.266.054-.365.41.167.58.55.803.886-.022.146-.026.285.13.369l.164.16c.25.335.473.689.786.97.093.12.216.207.343.286.166.152.373.264.48.475.081.22.211.352.467.263.452-.095.635.122.635.549-.177.332.082.442.293.576.198.097.326.3.553.35.13-.01.173-.166.301-.178.208.08.27.24.244.448.055.092-.207.024-.075.139.101.088.172.206.287.279.321.259.25.677.407 1.019.312-.207.365-.604.646-.816.224.118.181-.113.251-.197l.094-.114c.132-.118.198-.276.27-.431.086-.16.141-.332.19-.507.147-.308.46-.444.728-.552.594-.241 1.027-.7 1.554-1.025.202-.13.406-.255.587-.416.133-.101.255-.22.437-.226l.151.072a.383.383 0 01.033.328c-.243.21-.198.433-.029.662l-.047.067zM5.766 11.225c.473-.68.46-1.55.92-2.25.349-.53.769-.93 1.334-1.15.494-.192.97-.39 1.374-.738.39-.202.792-.383 1.16-.629.115-.06.242-.092.358-.153.147-.073.185-.236.284-.35.157-.152.345-.177.55-.135.11.027.218.066.323.112.12.06.227.137.262.273.05.2.102-.048.15.003.414.235.553.537.285.934-.43.637-.492 1.42-.93 2.06-.23.334-.45.612-.801.793l-.167-.018c-.196-.165-.203-.397-.221-.625-.011-.13.007-.293-.166-.323-.161-.029-.21.136-.298.23-.256.244-.542.436-.896.507a.84.84 0 00-.257.138c-.141.114-.165.29-.228.444-.108.151-.203.32-.431.298a.822.822 0 01-.266-.22c-.472-.53-.565-.535-1.131-.064-.336.322-.368.898-.888 1.067-.151.001-.326.04-.32-.204"
            fill="#E3AF51"
            fillRule="nonzero"
          />

          <Path
            d="M5.514 20.098c-.251-.319-.213-.752-.427-1.086.107-.388.248-.76.499-1.082.273-.335.6-.616.895-.93l.203-.233.146-.034.19.128c.07.081.115.173.12.28 0 .184.06.358.074.54-.027.391.102.76.156 1.137-.032.21-.2.261-.367.305-.22.058-.3.216-.318.423-.06.274-.253.41-.502.487l-.23.05-.222.016h-.217z"
            fill="#EFDFAC"
            fillRule="nonzero"
          />

          <G transform="translate(9.87 2)">
            <Mask fill="#fff" id="dDOGE_svg__h">
              <Use xlinkHref="#dDOGE_svg__g" />
            </Mask>

            <Path
              d="M1.101.023c.053.16.291.07.332.247-.099.134-.34.198-.214.44.056.124.1.25.062.386-.179.506-.026 1.041-.132 1.555-.031.148.012.313-.114.437-.203.164-.217.376-.223.625-.01.493-.389.825-.812.773.369-1.025.361-2.098.391-3.164C.399 1.01.388.7.491.396c.102-.3.277-.46.61-.373"
              fill="#E3AF51"
              fillRule="nonzero"
              mask="url(#dDOGE_svg__h)"
            />
          </G>

          <Path
            d="M9.87 6.487c.445-.136.731-.394.646-.902-.052-.304.09-.483.336-.614.166.213-.016.503.162.713.024.137-.016.264-.067.388a1.865 1.865 0 01-.33.444c-.346.325-.704.622-1.224.573.095-.252.267-.443.477-.602"
            fill="#C79335"
            fillRule="nonzero"
          />

          <Path
            d="M5.062 18.794c-.157-.216-.154-.48-.22-.726.1-.178.207-.353.337-.513l.184-.243c.123-.128.245-.258.39-.362a.864.864 0 01.367-.135l.157.038.136.193c-.158.354-.498.548-.736.831-.234.287-.33.666-.615.917"
            fill="#F0E0AD"
            fillRule="nonzero"
          />

          <Path
            d="M5.766 11.225c.085.152.23.127.368.124.35.198.248.484.119.747-.16.33-.374.627-.646.878-.122.09-.217.03-.307-.059l.01-.148.456-1.542"
            fill="#F0E0AF"
            fillRule="nonzero"
          />

          <Path
            d="M4.73 17.243c-.044-.273-.09-.548-.133-.822-.058-.142.06-.206.127-.295.288-.141.578-.275.913-.25.144.046.266.117.316.273.006.31-.265.394-.45.548-.176.1-.342.214-.452.393-.086.094-.161.213-.321.153"
            fill="#F0E0AD"
            fillRule="nonzero"
          />

          <Path
            d="M4.722 14.775c.148-.171.185-.387.243-.595l.152-.04c.387.123.748.293 1.048.575 0 .09-.048.155-.12.204l-.156.032c-.303-.033-.611-.109-.892.089l-.158.032-.176-.151.059-.146z"
            fill="#F0E0AE"
            fillRule="nonzero"
          />

          <Path
            d="M5.074 14.181h-.11l.331-1.182-.025-.084.042-.034.231.117.058.053c.055.092.076.195.101.298a.649.649 0 01-.103.605l-.234.216c-.095.06-.19.097-.29.011"
            fill="#F0E1B1"
            fillRule="nonzero"
          />

          <Path
            d="M4.614 16.064c-.01-.129-.031-.26.097-.352.11-.216.33-.24.53-.301l.157-.042c.166-.024.33-.034.48.061l.05.147-.187.147c-.097.043-.196.082-.292.129-.243.21-.535.228-.835.211"
            fill="#E1D4A5"
            fillRule="nonzero"
          />

          <Path
            d="M4.614 16.064c.244-.178.628-.029.81-.35.12.085.374-.039.377.235l-.14.003-.954.24-.111-.007.018-.12z"
            fill="#E0D1A1"
            fillRule="nonzero"
          />

          <Path
            d="M5.062 18.794c.17-.337.322-.686.598-.956l.036.08-.036.04c-.166.364-.303.745-.573 1.054l-.025-.218z"
            fill="#E6D7A8"
            fillRule="nonzero"
          />

          <Path
            d="M5.425 17.363l-.12.239c-.169.057-.244.3-.473.238-.018-.08-.132-.153-.015-.241.09-.16.098-.382.334-.43l.166.005c.081.038.145.085.108.189"
            fill="#F2E4B5"
            fillRule="nonzero"
          />

          <G transform="translate(4.487 16.795)">
            <Mask fill="#fff" id="dDOGE_svg__j">
              <Use xlinkHref="#dDOGE_svg__i" />
            </Mask>

            <Path
              d="M.7.453L.33.805.242.448.466.22c.12-.063.197.002.268.09L.7.454z"
              fill="#EFE1B1"
              fillRule="nonzero"
              mask="url(#dDOGE_svg__j)"
            />
          </G>

          <G transform="translate(4.482 14.881)">
            <Mask fill="#fff" id="dDOGE_svg__l">
              <Use xlinkHref="#dDOGE_svg__k" />
            </Mask>

            <Path
              d="M.126.592L.244.359.468.292a.83.83 0 01.37.161L.825.598V.597C.643.71.42.612.237.708L.126.592z"
              fill="#EBDDAB"
              fillRule="nonzero"
              mask="url(#dDOGE_svg__l)"
            />
          </G>

          <Path
            d="M5.666 19.968l.229-.005c.08.077.156.155.15.278l-.035.075c-.134.086-.11.222-.113.351l-.133-.208c-.056-.159-.155-.31-.098-.49"
            fill="#E3B154"
            fillRule="nonzero"
          />

          <Path
            d="M4.833 17.84c.164-.068.266-.258.473-.238a1.195 1.195 0 01-.464.466l-.01-.229z"
            fill="#E4D4A4"
            fillRule="nonzero"
          />

          <Path
            d="M5.666 19.968c.082.153.136.313.098.491a.616.616 0 01-.25-.36l.152-.13z"
            fill="#E3C272"
            fillRule="nonzero"
          />

          <Path
            d="M4.719 15.59c.167-.189.39-.085.588-.112-.156.186-.423.09-.596.234l.008-.123z"
            fill="#F2E2AE"
            fillRule="nonzero"
          />

          <Path
            d="M12.032 6.037c-.097-.001-.186-.021-.236-.116l-.049-.111.038-.238.19-.098c.133-.01.257.035.386.048a1.5 1.5 0 00.635-.06c.192-.065.393.005.586-.042.57.15 1.156.157 1.74.187.22.012.462-.011.586.246.041.086.113.204.214.13.59-.434 1.151-.008 1.723.039.14.169.333.287.437.487.171.2.343.398.532.582.187.523.773.764.932 1.307.097.166.287.14.432.21.068.128-.016.215-.08.306l.035.146.234-.055.108-.096c.298-.116.578.202.878.033.554.069.752-.354.992-.718.31-.303.491-.727.89-.946l.234.02c.115.162.032.358.09.53a.375.375 0 01-.14.346c-.098.058-.21.104-.226.24-.033.094-.104.184-.029.288-.03.115-.13.207-.114.336l-.025.132.044-.158c.028-.136.096-.238.224-.293l.141.055c.064.05.1.159.215.1.07-.083.091-.202.196-.256.312.645.678 1.277.925 1.936.283.754.413 1.594.437 2.402.015.462.124.894.158 1.344l.002.16c.13.217.215.468-.003.64-.228.178-.342-.147-.538-.23-.17.372-.39.679-.82.763-.166.033-.227.177-.279.342-.112.352-.12.34-.426.074-.045-.04-.186.062-.247-.063-.233-.356-.3-.703-.295-1.165.006-.512.043-1.097-.185-1.613.017-.107.143-.214-.005-.32-.269-.195-.094-.731-.585-.751l-.224-.045c-.142-.017-.284-.032-.412.055l-.166.24-.13.088c-.348-.057-.686-.142-.993-.323-.222-.212-.427.022-.64.027a2.822 2.822 0 01-.588.02c-.34-.02-.547.093-.76.404-.533.778-1.259 1.362-2.12 1.77-.326.154-.61.03-.904-.081-.193-.074-.41-.093-.588-.181-.204-.1-.153.11-.238.14a.591.591 0 01-.41.028l-.199-.152c-.079-.147-.14-.298-.112-.47.157-.105.005-.183-.028-.272a1.533 1.533 0 01-.087-.297c-.035-.095.033-.256-.163-.243l-.12.08c-.198.155-.296.559-.619.39-.29-.15-.234-.494-.187-.79.081-.516.33-.99.381-1.51l-.028-.106-.112.17c-.154.193-.299.397-.58.407-.209.014-.407.06-.578.192-.175.143-.29.368-.545.404-.185.034-.299.148-.353.327-.308.334-.458.17-.482-.181a1.856 1.856 0 01.086-.617c.061-.211.53-.223.283-.585l-.02-.146c.075-.117.2-.154.317-.198.206-.097.148-.294.173-.46a.42.42 0 01.134-.29c.706-.548 1.083-1.282 1.286-2.142.051-.22.215-.442.352-.654.197-.306.324-.701-.223-.865-.11-.088-.099-.365-.355-.234"
            fill="#C89436"
            fillRule="nonzero"
          />

          <Path
            d="M23.127 15.954c.352-.246.185.247.416.254-.008-.563.278-.853.86-.843.043 0 .094-.197.135-.305.15-.391.247-.411.54-.12l.204.086c.093-.01.066-.115.088-.184.06-.184-.208-.361-.005-.542.306.419.43.894.498 1.405.065.478.207.946.315 1.419-.031.44-.066.882.206 1.277.216.316-.041.645.014.966.08.214-.256.364-.09.592-.215.762-.704 1.382-1.102 2.044-.086.144-.44.13-.671.188-.176-.008-.283.047-.23.25.134.31-.076.482-.295.579-.436.194-.852.455-1.345.496l-.043-.071c.208-.662.754-1.121 1.045-1.742.176-.377.45-.692.252-1.142-.085-.192.042-.325.145-.474.389-.56.768-1.127.96-1.792.065-.16.05-.333.05-.377a.882.882 0 01-.077.41c-.292.39-.57.78-1.066.955-.186.066-.256.322-.346.483-.387.679-.884 1.227-1.564 1.608l-.038.149c.003.167-.16.262-.17.419.07.178.248.276.33.443.162.205.104.444.103.675a.954.954 0 01-.225.489c-.175.182-.361.355-.468.592l-.197.069c-.242.01-.507.148-.701-.123-.264-.443.159-.61.362-.843.245-.281.419-.52-.006-.78l-.055-.22-.044-.217-.027-.146c-.018-.175.029-.342.063-.51.034-.158-.078-.265-.142-.388-.113-.633.172-1.084.68-1.41a.892.892 0 00.319-.324c.108-.205.006-.38-.081-.557-.193-.427.065-.572.412-.662.083-.12.166-.241.304-.305.157-.341.009-.707.083-1.053.024-.276.3-.393.39-.63.055-.068.113-.13.214-.088"
            fill="#D49930"
            fillRule="nonzero"
          />

          <G transform="translate(22.846 22.127)">
            <Mask fill="#fff" id="dDOGE_svg__n">
              <Use xlinkHref="#dDOGE_svg__m" />
            </Mask>

            <Path
              d="M1.935 5.042c.306-.27.56-.576.697-.967l.087.07.021-.188c.424-.133.579-.39.374-.811-.076-.185-.013-.337.114-.474.023-.646-.028-1.313.433-1.85.162-.188-.001-.366.044-.547.093-.381.249-.326.442-.075.293 1.33.324 2.69.442 4.04.059.673-.222 1.184-.697 1.65-.761.748-1.574 1.412-2.527 1.898-.168-.042-.344-.06-.495-.156l-.058-.006-.059.002c-.101.027-.111.213-.263.152l-.218-.2C.141 7.445.029 7.3 0 7.107c.078-.294.131-.606.512-.666l.714-.342c.199-.196.057-.565.359-.707.19-.043.3-.166.35-.35"
              fill="#D49A30"
              fillRule="nonzero"
              mask="url(#dDOGE_svg__n)"
            />
          </G>

          <Path
            d="M20.184 8.635c-.169-.007-.385.167-.48-.124-.297-.587.037-1.168.017-1.752a1.39 1.39 0 01.116-.39c.138-.23.326-.42.491-.628.113-.195.36-.349.191-.63l-.058-.135c.016-.216.16-.354.313-.47.438-.334.654-.8.814-1.305.35-.56.851-.629 1.55-.21-.05.28.07.54.103.81-.01.226-.114.444-.046.676.007.288.41.418.302.752l-.209.108a3.08 3.08 0 00-.305.094c-.475.156-.624-.272-.94-.518-.053.25-.213.434-.389.607l-.168.224c.195.524-.283.697-.499 1.004-.148.23.091.31.173.45l.024.24-.128.208c-.28.231-.353.58-.49.89-.11.098-.23.164-.382.1"
            fill="#C89436"
            fillRule="nonzero"
          />

          <Path
            d="M23.133 3.79c.035-.266-.444-.535.004-.8.38.135.663.338.576.806-.107.209-.107.38.162.457.244 1.026.413 2.058.2 3.112-.04.2-.015.432-.226.567-.169.093-.267-.033-.376-.123-.208-.17-.05-.317.027-.47-.092-.316-.007-.636.117-.9.135-.285-.149-.216-.189-.334-.072-.15-.02-.302-.01-.453-.043-.155-.15-.32.047-.448.047-.313-.54-.311-.347-.697-.192-.243.171-.475.015-.717"
            fill="#855113"
            fillRule="nonzero"
          />

          <Path
            d="M13.582 5.42c-.16.231-.403.117-.613.145-.106.052-.198-.005-.294-.038-.112-.067-.2-.164-.312-.232-.39-.218-.44-.653-.637-.995-.067-.16-.046-.348-.148-.5l-.082-.129c-.057-.115-.066-.252-.16-.347-.085-.08-.203-.127-.259-.238l-.096-.358c.04-.326.254-.299.483-.244.556.407.851.993 1.123 1.598.232.52.506 1.01.995 1.337"
            fill="#BB7B22"
            fillRule="nonzero"
          />

          <Path
            d="M20.412 5.801l-.48.605a1.004 1.004 0 00-.271.332l-.106.107c-.198.117-.335.315-.551.41-.13.02-.182-.083-.252-.158-.338.021-.325-.392-.582-.47.012-.194-.127-.387 0-.58.562.041 1.042-.128 1.413-.566.179.163.242-.003.324-.117.174-.16.3-.423.613-.283l.01.012c.06.253.053.494-.118.708"
            fill="#C89436"
            fillRule="nonzero"
          />

          <Path
            d="M26.314 19.382c-.172-.12-.123-.273-.039-.406.152-.24.078-.463-.083-.632-.34-.357-.088-.779-.167-1.162l.152-.059c.567.99.868 2.05.829 3.196-.132.063-.183.031-.246-.118-.083-.195-.029-.437-.21-.593-.106-.046-.151-.157-.236-.226"
            fill="#E3C272"
            fillRule="nonzero"
          />

          <Path
            d="M20.52 5.082c-.263.014-.368.272-.576.37l-.254-.013c-.019-.392.31-.571.523-.808.442-.492.915-.954 1.375-1.43.058.563-.214.972-.617 1.315-.188.16-.41.289-.451.566"
            fill="#E3B053"
            fillRule="nonzero"
          />

          <Path
            d="M23.473 7.808l.376.123-.029.35c-.137.188.078.466-.16.63-.136.008-.209-.084-.288-.169l-.01.01-.063-.12c.004-.087.091-.147.072-.239l-.029-.235.131-.35"
            fill="#7B4408"
            fillRule="nonzero"
          />

          <G transform="translate(10.679 2)">
            <Mask fill="#fff" id="dDOGE_svg__p">
              <Use xlinkHref="#dDOGE_svg__o" />
            </Mask>

            <Path
              d="M.786.485C.625.567.412.545.303.728.145.375.169.34.623.27l.163.214z"
              fill="#BB8025"
              fillRule="nonzero"
              mask="url(#dDOGE_svg__p)"
            />
          </G>

          <Path
            d="M18.17 6.047c.025.193.163.386 0 .58-.162-.164-.163-.473-.47-.518l.146-.087c.108.007.216.016.324.025"
            fill="#C58E30"
            fillRule="nonzero"
          />

          <Path
            d="M23.715 29.758c.213-.1.364-.005.495.156l-.133.088c-.146-.042-.366.024-.362-.244"
            fill="#D29223"
            fillRule="nonzero"
          />

          <Path
            d="M19.69 5.439l.254.013c-.019.141-.05.297-.22.3-.171.001-.121-.165-.141-.271l.107-.042z"
            fill="#D6A243"
            fillRule="nonzero"
          />

          <Path
            d="M23.607 8.866c.213-.144.005-.44.213-.585l.025.337c-.028.137.021.295-.113.402-.11.004-.169-.034-.125-.154"
            fill="#845013"
            fillRule="nonzero"
          />

          <Path
            d="M23.876 4.253c-.376-.04-.328-.228-.162-.457.055.152.108.305.162.457"
            fill="#7D460F"
            fillRule="nonzero"
          />

          <Path
            d="M23.358 28.568c-.266.167-.316.476-.472.716l-.097.01c-.063-.441.054-.74.569-.726"
            fill="#E8D396"
            fillRule="nonzero"
          />

          <Path
            d="M23.004 29.971c-.634.015-.223-.348-.205-.568.173-.004.203.205.353.236.04.15-.039.248-.148.332"
            fill="#D9A646"
            fillRule="nonzero"
          />

          <Path
            d="M15.22 28.815c-.044-.135-.2-.28-.046-.407.208-.173.267.111.4.172l-.354.235"
            fill="#E3C578"
            fillRule="nonzero"
          />

          <Path
            d="M23.366 29.862c.116.048.084-.228.233-.107-.103.102.073.609-.353.245-.037-.114.009-.155.12-.138"
            fill="#E7CE8F"
            fillRule="nonzero"
          />

          <Path
            d="M23.366 29.862l-.12.138-.241-.03.124-.323.116-.005.12.22z"
            fill="#DBAC51"
            fillRule="nonzero"
          />

          <Path
            d="M23.245 29.642l-.116.005c-.18.014-.228-.15-.33-.244l-.01-.11.098-.009c.119.12.238.24.358.358"
            fill="#D29426"
            fillRule="nonzero"
          />

          <Path
            d="M13.687 29.878l.119.23c-.104-.007-.246.04-.291-.084-.046-.133.09-.125.172-.146"
            fill="#E5CA83"
            fillRule="nonzero"
          />

          <Path
            d="M21.961 19.255c-.052.491-.649.425-.833.841-.14.315-.27.593-.223.935.013.132-.253.2-.083.367l-.14.17-.198.02-.048-.002.013.038a.354.354 0 01.275.194l.043.136.073.18.13.136.025.056c.657.368.66.47.076 1.047-.199.196-.485.356-.417.713-.16.161-.37.21-.582.213-.037 0-.489.004-.104-.258l-.064-.159c-.265.057-.5-.126-.776-.056-.223.057-.528.271-.644-.202-.033-.136-.288-.13-.473-.047-.333.15-.68.266-1.022.396a.558.558 0 01-.354-.116v-.12c.377-.337.63-.848 1.217-.897.192-.175-.197-.259-.068-.422.487-.442.688-.484 1.18-.248-.2-.275-.547-.297-.944.026-.264.214-.512.26-.813.171-.14-.04-.176-.14-.16-.272.235-.637.917-.81 1.328-1.272.26-.292.443-.564.35-.963l.065-.23c.151-.232.308-.459.625-.473l.108-.04c.236-.538.793-.757 1.146-1.174l-.17-.1c-.06-.113-.03-.209.048-.297l.208-.115-.252.13-.197.251c-.825.302-1.64.631-2.429 1.021-.197.098-.27.271-.312.471-.063.29-.16.548-.543.436-.22-.063-.14.12-.153.197-.029.184-.076.378-.252.441-.424.153-.783.45-1.229.55-.11.016-.266-.032-.24.175-.045.255-.16.443-.444.472-.15.003-.267.13-.432.115l.033.092c-.655.515-1.195.522-1.877.073-.34-.225-.84-.215-1.017-.686-.013-.229.152-.279.324-.32.194-.027.385.107.58.01l.068-.113c.115-.043.091-.147.107-.234.242-.369.59-.55 1.023-.577.262-.017.487-.113.68-.294.6-.56.566-.996-.127-1.562-.148-.08-.304-.146-.455-.22-.229-.115-.292-.278-.143-.5.121-.16.317-.216.47-.332l.212-.115c.126-.039.254-.07.386-.093.23-.023.305.168.424.3.086.09.171.18.231.29.126.243.155.003.223-.049l.131-.065a.71.71 0 01.302.057l.152.041c.522.2.95.115 1.441-.214 1.127-.755 2.393-1.158 3.775-1.107.182.07.358.013.536-.02.42.016.842-.194 1.26.016.133.35-.379.304-.359.583-.067.083-.107.182-.158.274-.087.26 0 .55-.137.802-.168.113-.069.328-.175.465-.334-.004-.55.08-.349.471.056.195.107.39.128.592"
            fill="#E7CF90"
            fillRule="nonzero"
          />

          <Path
            d="M22.665 23.516c-.201.244-.376.521-.723.586l-.154-.024c-.09-.315.159-.426.346-.577.17-.08.34-.154.513-.007l.018.022z"
            fill="#D49930"
            fillRule="nonzero"
          />

          <Path
            d="M24.78 27.169c.176.138.085.173-.044.305-.132.136-.17.215-.305.045.067-.166.116-.352.35-.35"
            fill="#D4982E"
            fillRule="nonzero"
          />

          <Path
            d="M26.074 24.798c.02.173-.036.326-.114.475-.38-.259-.22-.388.114-.475"
            fill="#DBAE50"
            fillRule="nonzero"
          />

          <Path
            d="M21.82 24.078l.122.024c-.196.032-.304.542-.586.108l.118-.127c.115-.082.23-.092.347-.005"
            fill="#E2C170"
            fillRule="nonzero"
          />

          <Path
            d="M26.314 19.382c.167-.017.218.087.236.226-.074.122-.25.172-.243.35-.245-.195.03-.384.007-.576"
            fill="#D29325"
            fillRule="nonzero"
          />

          <Path
            d="M24.305 22.44c-.089-.014-.219-.02-.122-.152.078-.107.177-.28.351-.097l-.229.25z"
            fill="#D29528"
            fillRule="nonzero"
          />

          <Path
            d="M16.635 23.857c.13.004.27-.022.354.116-.202.214-.265.011-.354-.116"
            fill="#E3C577"
            fillRule="nonzero"
          />

          <Path
            d="M25.586 26.084c.14.15.017.264-.07.474-.02-.176-.028-.266-.037-.356l.107-.118z"
            fill="#D19224"
            fillRule="nonzero"
          />

          <Path
            d="M11.445 21.141c.352.475.98.472 1.428.84.384.314.95-.038 1.404-.247.19.028.294-.07.353-.237.31-.004.357-.275.475-.472.016-.14.154-.158.233-.236.103-.086.44.198.34-.23-.044-.185.152-.112.247-.118.436-.027.855-.035.791-.677-.016-.159.066-.41.366-.129.178.167.28-.078.344-.278.062-.189-.087-.479.203-.56.483-.137.821-.596 1.335-.609.132-.003.271.028.36-.059.275-.263.526-.553.968-.41.1-.002.175.047.237.12.073.068.428-.229.236.158-.12.245-.249.545-.612.553l-.16.042c.047.793-.898.606-1.114 1.133-.128-.073-.16.026-.184.113l.026.048.04-.038c.128.106.528.12.137.406-.129.094-.266.194-.085.37l-.017.152c-.548-.007-.593.71-1.11.761-.31.031-.312.44-.575.55-.01.384-.278.511-.593.588-.113.077-.225.154-.337.233-.278-.002-.466.263-.747.253a1.324 1.324 0 01-.397-.028c-.174-.059-.343-.07-.5.05l-.154.02c-.092-.023-.209-.124-.23.077-.203.163-.454.158-.689.21-.144.011-.28-.028-.418-.055-.16-.02-.325-.026-.484-.063-.219-.068-.435-.12-.615.09a.623.623 0 01-.36.155c-.187.003-.37-.06-.558-.009a.671.671 0 01-.475-.089c-.307-.21-.65-.19-.995-.15-.43-.103-.816-.327-1.239-.446l-.2-.138-.026-.159c0-.39-.372-.4-.589-.568-.193-.089-.389-.175-.546-.326l-.109-.113c-.032-.124-.234-.185-.132-.359-.287-.276-.545-.575-.709-.944l.006.001c.05-.124.111-.238.236-.303.14-.055.294-.065.432-.13.115-.046.231-.088.351-.115.191-.049.245-.214.307-.37.077-.19.03-.386.022-.58-.023-.18-.187-.344-.058-.54l.242-.017c.341.464.817.753 1.315 1.008.054.018-.086.161.065.113.125-.04.236-.123.364-.165l.162.018c.214.162.176.37.122.565.166-.262-.14-.574.04-.852l.136-.093c.208.003.38.065.457.282l.062.198.085-.21c.197-.08.36-.012.501.122.015.121.052.182.16.056a1.68 1.68 0 00.252-.254l.215-.236a.986.986 0 01.482-.175c.231-.018.462-.01.693-.012.46-.08.994.093 1.331-.396a.554.554 0 01.166-.272c.47.231.634.685.67 1.132.026.33-.819.985-1.192.98-.5-.005-.852.211-1.126.598-.245.198-.545.114-.818.162-.06.121-.33.075-.244.31"
            fill="#E2C170"
            fillRule="nonzero"
          />

          <Path
            d="M11.557 23.51l.357-.123c.142-.14.313-.127.488-.092l.22.096a.82.82 0 00.35.092c.164-.012.336.033.478-.09.204-.195.516-.054.725-.233l.219-.01.12-.012c.14-.128.312-.107.48-.09l.46-.004c.076.058.13.125.125.226-.364.466-.836.801-1.364 1.041-.335.153-.645.321-.89.598-.167.118-.154.32-.225.482-.04.181-.061.372-.248.476-.073-.069-.173-.12-.17-.243l-.001-.22-.072.138c-.076.138-.028.283-.032.425l-.074.127-.097.14c-.166.323-.301.81-.654.81-.346 0-.13-.528-.293-.786-.07-.109-.061-.263-.235-.28-.148-.13.029-.345-.125-.474-.052-.17-.08-.355-.246-.467-.147.071-.1.277-.242.35-.254-.087-.407-.304-.6-.47-.177-.158-.42-.278-.347-.585-.022-.373-.095-.682-.586-.467-.254 0-.306-.243-.45-.376-.168-.146-.401-.223-.495-.45-.07-.136.025-.185.123-.232.479.023.878.272 1.296.468.349.09.72-.03 1.064.111.12.184.306.098.468.11.159-.018.31.143.473.013"
            fill="#BA7A20"
            fillRule="nonzero"
          />

          <Path
            d="M16.518 22.675c.147-.246.508-.278.593-.588.175.252.535.295.656.16.342-.384.841-.353 1.215-.599.183.093.07.255.08.37.013.14.12.496-.292.165-.223-.179-.667.016-.947.26-.11.19.082.31.108.468-.097.152-.3.102-.407.21-.104.103-.599-.087-.29.382.042.066-.356.237-.6.234.024-.284-.28-.175-.388-.31.081-.19.23-.3.39-.4.094-.117.183-.233 0-.349l-.118-.003z"
            fill="#E2C170"
            fillRule="nonzero"
          />

          <Path
            d="M6.96 21.732c.214.042.429.083.604.229.15.28.69.236.597.719l-.021-.003c-.077.168-.208.16-.352.107a2.253 2.253 0 01-.828-1.052"
            fill="#BB7B21"
            fillRule="nonzero"
          />

          <Path
            d="M15.579 23.27c-.093-.047-.12-.13-.124-.226.26.047.434-.359.727-.136-.18.155-.31.395-.603.362"
            fill="#BB7A20"
            fillRule="nonzero"
          />

          <Path
            d="M9.664 24.333l.348.584c-.257-.11-.642-.146-.348-.584"
            fill="#E7B455"
            fillRule="nonzero"
          />

          <Path
            d="M8.628 23.49c.24.017.31.239.45.376-.352.117-.46-.06-.45-.376"
            fill="#E5B253"
            fillRule="nonzero"
          />

          <Path
            d="M12.515 25.984c-.073-.16-.223-.318-.01-.482.127-.047.195.015.239.124l.108.242-.111-.003c-.063.062-.098.18-.226.119"
            fill="#D59D40"
            fillRule="nonzero"
          />

          <Path
            d="M11.1 25.504c.39.066.063.321.123.474-.043-.158-.38-.238-.124-.474"
            fill="#E5B253"
            fillRule="nonzero"
          />

          <Path
            d="M16.635 22.678l.408-.086c-.084.23-.24.337-.408.435v-.349z"
            fill="#E3AE4F"
            fillRule="nonzero"
          />

          <Path
            d="M7.788 22.784l.352-.106.116.129-.123.232a.366.366 0 01-.345-.255"
            fill="#C28328"
            fillRule="nonzero"
          />

          <Path
            d="M6.719 21.26c.002.135.263.174.132.357-.14-.01-.281-.04-.316-.196-.03-.132.09-.143.184-.16"
            fill="#E3B154"
            fillRule="nonzero"
          />

          <Path
            d="M12.515 25.984l.226-.12c-.021.09-.047.18-.061.27-.015.098.05.25-.135.219l-.042-.26.012-.109z"
            fill="#E3B051"
            fillRule="nonzero"
          />

          <Path
            d="M10.61 25.386c.102-.102-.101-.414.243-.349-.003.17.008.35-.242.35"
            fill="#D49B3E"
            fillRule="nonzero"
          />

          <Path
            d="M13.1 25.391c-.04-.214-.143-.458.226-.48a.629.629 0 01-.226.48"
            fill="#DAA345"
            fillRule="nonzero"
          />

          <Path
            d="M5.544 12.999c.064-.346.359-.546.525-.83.158-.27.255-.528.065-.82.185-.306.543-.51.522-.945-.005-.113.186-.108.297-.136.81-.27.862-.221.844.77.016.17.058.336.08.504-.032.35-.033.703-.117 1.047-.236.342.026.763-.174 1.11l-.084.147a.661.661 0 01-.267.197 1.806 1.806 0 01-.394.118l-.242-.025c-.3-.098-.453-.357-.641-.58A2.386 2.386 0 015.544 13z"
            fill="#FAEDC8"
            fillRule="nonzero"
          />

          <Path
            d="M10.401 10.877l-.042.104c-.083.242-.254.427-.408.624l-.153.07-.244-.05-.087-.138c-.035-.387-.493-.67-.279-1.12.096-.081.215-.081.22-.082-.027.008-.168-.011-.306.019l-.229.034-.146-.035c-.024-.211-.01-.406.232-.492a1.56 1.56 0 01.588-.112c.407-.017.662.178.784.56.09.198.133.402.07.618"
            fill="#0C0D08"
            fillRule="nonzero"
          />

          <Path
            d="M7.667 10.994l-.044-.22c.05-.343.182-.751-.43-.431-.104.054-.184.027-.24-.076.388-.521.83-.534 1.192-.034.089.123.174.249.26.374v.076a.625.625 0 01-.143.274.497.497 0 01-.288.142.357.357 0 01-.307-.105"
            fill="#F0DFAB"
            fillRule="nonzero"
          />

          <Path
            d="M10.137 9.21c-.047-.301.168-.39.383-.387.238.004.334.258.291.422-.064.248.057.458.04.687.124.143.01.258-.045.38l-.212.134c-.125-.002-.207-.068-.26-.175.198-.221.085-.395-.067-.583-.106-.132-.228-.281-.13-.477"
            fill="#BA7920"
            fillRule="nonzero"
          />

          <Path
            d="M10.137 9.21c.077.137.125.305.236.4.27.239.283.448.007.676l-.129.003c-.184-.17-.213-.525-.576-.476-.135-.136-.348.02-.48-.128.314-.16.628-.317.942-.474"
            fill="#BA7A20"
            fillRule="nonzero"
          />

          <Path
            d="M11.797 5.801v.12c-.207-.016-.405.001-.579.128l-.116.047-.25-.06.12-.353c.068-.069.154-.086.247-.08.2.045.42.034.578.198"
            fill="#BA7C22"
            fillRule="nonzero"
          />

          <Path
            d="M10.852 6.037c.08-.011.16-.132.238.002.065.152-.033.25-.115.355-.086.139-.23.109-.358.122-.051-.223.106-.344.235-.48"
            fill="#B6741B"
            fillRule="nonzero"
          />

          <Path
            d="M8.727 10.302l.127-.056.05.063.014.156-.06.145c-.16.184-.054.472-.252.642-.292-.033-.21-.278-.256-.452l.031-.149.023-.044.323-.305"
            fill="#7F7762"
            fillRule="nonzero"
          />

          <Path
            d="M12.032 6.037c.04-.092.103-.197.202-.16.175.066.151.243.153.394-.217.072-.172-.254-.355-.234"
            fill="#E5B152"
            fillRule="nonzero"
          />

          <Path
            d="M9.195 9.684c.164.03.354-.043.48.128-.238 0-.477 0-.716-.002l.236-.126z"
            fill="#110C05"
            fillRule="nonzero"
          />

          <Path
            d="M10.975 6.394l.115-.355.129.01c-.016.161-.017.333-.244.345"
            fill="#C79335"
            fillRule="nonzero"
          />

          <Path
            d="M10.729 10.279l.122-.348h.122l-.004.349-.16.155c-.08-.025-.146-.057-.08-.156"
            fill="#DFAC4D"
            fillRule="nonzero"
          />

          <Path
            d="M7.43 18.898c.158.16.063.318 0 .48-.08.125-.182.22-.34.236l-.174.01a.423.423 0 01-.312-.133c-.144-.658.458-.418.715-.593h.112z"
            fill="#EEDDA7"
            fillRule="nonzero"
          />

          <Path
            d="M6.604 19.49l.352.002c.18.285-.108.338-.233.472-.125.169-.317.088-.475.129-.102.027-.131-.024-.122-.115.158-.164.43-.216.478-.487"
            fill="#EFDFAD"
            fillRule="nonzero"
          />

          <Path
            d="M7.055 17.14l-.092-.245.042-.063c.175-.13.377-.124.58-.11l.116.18c-.06.143-.123.286-.015.436.04.114-.006.201-.088.278l-.15.034-.197-.119c-.072-.127-.218-.217-.196-.39"
            fill="#8F856D"
            fillRule="nonzero"
          />

          <Path
            d="M6.794 16.771l-.07.008-.145.02c-.136-.132-.219-.305-.348-.443l-.055-.155.011-.085.105-.133c.218-.116.398-.087.523.142.032.216.229.44-.021.646"
            fill="#F0EBDB"
            fillRule="nonzero"
          />

          <Path
            d="M7.43 18.898h-.11c-.22-.405-.281-.839-.241-1.292l.148-.009c.092.116.099.258.118.396.012.107.036.217-.031.317l.118.588"
            fill="#E3C77E"
            fillRule="nonzero"
          />

          <Path
            d="M5.66 17.958v-.12l.708-.825.238-.001a4.686 4.686 0 01-.946.946"
            fill="#DDCF9F"
            fillRule="nonzero"
          />

          <Path
            d="M6.794 16.771l-.093-.711c-.009-.1.047-.139.14-.138a.64.64 0 01.276.283c.024.19-.014.372-.059.554l-.086.135-.009.001-.169-.124z"
            fill="#A19A8A"
            fillRule="nonzero"
          />

          <Path
            d="M6.606 17.011l-.239.001-.118-.116.021-.232c.135-.074.237.03.334.112l.12.003c-.005.094-.024.182-.118.232"
            fill="#EADBAA"
            fillRule="nonzero"
          />

          <Path
            d="M6.126 19.978l.121.114-.232.225-.12-.354.231.015z"
            fill="#E3C272"
            fillRule="nonzero"
          />

          <Path
            d="M7.184 17.595l-.106.011c.042-.157-.202-.302-.023-.466l.261.34-.132.115z"
            fill="#FDECB5"
            fillRule="nonzero"
          />

          <Path
            d="M11.209 5.685l-.236-.002c-.49-.162-.09-.473-.12-.712-.006-.523.187-1.043.039-1.57-.048-.17.062-.246.186-.315l.35.116c-.015.203.1.387.079.592-.2.296-.241.62-.157.961a.61.61 0 00.165.402l.114.204-.044.233c-.112.083-.231.14-.376.091"
            fill="#C99637"
            fillRule="nonzero"
          />

          <Path
            d="M6.604 16.776c-.117.043-.33-.192-.354.12l-.117-.001a.32.32 0 01-.208-.272c.023-.195.065-.37.326-.32.282.036.31.26.353.473"
            fill="#E5D6A5"
            fillRule="nonzero"
          />

          <Path
            d="M6.014 16.659l.118.236-.353.118c-.136.053-.218-.018-.288-.121l-.008-.173.115-.13a.353.353 0 01.416.07"
            fill="#E2D3A3"
            fillRule="nonzero"
          />

          <Path
            d="M5.538 16.892l.241.12a.728.728 0 01-.354.351l-.119-.116c-.114-.243-.036-.36.232-.355"
            fill="#DFD1A1"
            fillRule="nonzero"
          />

          <Path
            d="M6.014 16.659l-.473-.001-.12-.001c.16-.155.318-.31.477-.467.113-.105.228-.098.343-.004l.01.118a.355.355 0 00-.237.355"
            fill="#DCCE9F"
            fillRule="nonzero"
          />

          <Path
            d="M5.421 16.657h.12v.118a.988.988 0 00-.352.359l-.238-.12c.102-.191.232-.344.47-.357"
            fill="#E0D1A1"
            fillRule="nonzero"
          />

          <Path
            d="M6.241 16.186l-.343.004-.237-.238.116-.003c.196-.143.351-.108.461.11l.003.127z"
            fill="#F5E4B0"
            fillRule="nonzero"
          />

          <Path
            d="M4.957 15.244l-.23-.004-.03.002c-.045-.13.048-.18.133-.235l.122-.001c.096-.093.216-.116.344-.116.24-.04.478.066.717-.001l.12-.12.151.028a.477.477 0 01.087.317l-.079.234c-.097.084-.202.049-.307.019l-.147-.048a1.246 1.246 0 00-.666-.032l-.215-.043z"
            fill="#EFDFAC"
            fillRule="nonzero"
          />

          <Path
            d="M6.243 14.768l-.11.001c-.335-.228-.752-.308-1.058-.588.095-.005.182-.024.23-.119.294-.084.485.173.739.22.106.035.204.081.262.184l-.003.243-.06.06z"
            fill="#DFD0A1"
            fillRule="nonzero"
          />

          <Path
            d="M5.304 15.003l-.352.002c.269-.409.607-.272.946-.117-.178.137-.416-.026-.594.115"
            fill="#DBCD9E"
            fillRule="nonzero"
          />

          <Path
            d="M5.304 15.003c.168-.19.394-.087.594-.115h.115c-.208.213-.472.08-.71.115"
            fill="#E9DAA8"
            fillRule="nonzero"
          />

          <Path
            d="M6.017 13.472l.587.588c-.148.173-.342.212-.557.197-.198-.055-.378-.142-.506-.313.018-.197.166-.393.002-.59.207-.158.322.053.474.118"
            fill="#FAEEC9"
            fillRule="nonzero"
          />

          <Path
            d="M5.541 13.944c.187.018.35.088.473.233l.002.235c-.287-.016-.45-.28-.71-.35.05-.096.138-.116.235-.118"
            fill="#E5D7AE"
            fillRule="nonzero"
          />

          <Path
            d="M6.017 13.472l-.474-.118v-.356c.2.118.359.274.474.474"
            fill="#E8DCB9"
            fillRule="nonzero"
          />

          <Path
            d="M6.238 16.06a.801.801 0 00-.46-.11l-.354-.237.237-.117c.185.183.48-.064.652.167a.275.275 0 01-.06.305l-.015-.009z"
            fill="#E4D6A5"
            fillRule="nonzero"
          />

          <Path
            d="M6.006 15.248l.22.115c.095.01.153.063.172.157-.027.093-.089.14-.187.137-.113.02-.228.048-.316-.061v-.12l-.04-.11.151-.118z"
            fill="#E2D4A5"
            fillRule="nonzero"
          />

          <Path
            d="M5.896 15.595l.354.001-.012.12c-.233.156-.415.068-.576-.12h.234z"
            fill="#EFDFAD"
            fillRule="nonzero"
          />

          <Path
            d="M5.895 15.361v.114c-.197 0-.393.003-.589.004l.001-.114c.195-.17.391-.142.588-.004"
            fill="#F3E3AF"
            fillRule="nonzero"
          />

          <Path
            d="M5.189 17.133c.006-.229.127-.347.354-.358l-.004.117a.36.36 0 00-.233.355h-.118v-.114z"
            fill="#F4E4B0"
            fillRule="nonzero"
          />

          <Path
            d="M5.895 15.361c-.196 0-.392.003-.588.004-.126-.014-.269.02-.35-.121l.231-.006c.236-.082.472-.087.707.003l.111.007-.11.113z"
            fill="#CFC295"
            fillRule="nonzero"
          />

          <Path
            d="M21.243 16.178c-.721.084-1.498.03-2.145.294-.63.257-1.313.521-1.845.957-.396.323-.68.334-1.061.09-.076-.049-.19.08-.266-.034-.13-.14-.06-.364-.21-.495-.104-.135-.252-.153-.407-.153l-.141-.055a1.067 1.067 0 00-.432-.286c-.145-.07-.292-.134-.417-.24-.064-.066-.094-.14-.06-.233l.2-.146c.214.028.43.006.615.018-.163-.052-.377.055-.564-.057-.07-.146.075-.2.125-.295.01-.094-.06-.16-.084-.243l.004-.21.024-.047.039-.04c-.287 0-.606.111-.797-.228-.05-.196-.175-.39-.012-.589l.464-.023c.238-.066.473-.056.708.011 1.138.378 1.783-.429 2.469-1.068.26-.243.672-.427.629-.922-.016-.176.208-.134.334-.134.352.001.705.018 1.057.028.2.094.384-.187.586-.023.333.185.745.127 1.065.35l.07.077.095-.034-.164-.159c-.011-.105-.023-.212.107-.259.182-.059.368-.022.552-.032l.174.052c.294-.09.53-.025.512.316-.013.227.148.333.204.504l.005.258c-.03.37.249.66.166 1.095-.06.31.173.726.025 1.11-.113.294.36.335.26.62l-.116.116c-.43.09-.865.13-1.304.112-.153-.1-.31-.03-.464-.003"
            fill="#FAEDC9"
            fillRule="nonzero"
          />

          <Path
            d="M9.803 11.591l.09-.016.26.01c.087.233.016.455-.05.677-.036.12.022.203.12.223.131.026.146-.092.163-.188.117-.12.262-.21.36-.35.338.093.313-.373.585-.38.181-.105.467.063.58-.22.341.106.325-.348.582-.37l.251.014c.32.062.11.238.054.337-.23.422-.256.901-.399 1.345-.068.212 0 .43.151.605.355.008.212-.642.666-.503.18.154.074.473.337.58.01.08.16.145.021.238-.147.13.02.304-.062.443l-.105.125c-.343.175-.66.412-1.06.455-.467-.12-.905-.423-1.424-.23l-.164-.016c-.173-.072-.336-.17-.525-.204-.185-.056-.349-.156-.52-.24a2.204 2.204 0 00-.727-.175c-.299-.017-.599.016-.896-.03l-.165-.07c-.219-.293.066-.4.206-.562.215-.187.432-.374.588-.618l.091-.123c.121-.127.284-.208.382-.36l.094-.116c.133-.165.37-.14.516-.28"
            fill="#E9C179"
            fillRule="nonzero"
          />

          <Path
            d="M13.684 9.694c.342-.793.945-1.17 1.536-.959.546.194.797.689.643 1.222-.052.179-.041.377-.06.566l-.14.063c-.036.099.067.036.102.067.082.077.284.084.136.275-.191.126-.428.069-.627.162-.26.063-.441.361-.757.258l-.24-.117c-.242-.057-.515-.024-.704-.238l-.12-.822c.156-.122.142-.324.23-.477"
            fill="#E3AF50"
            fillRule="nonzero"
          />

          <Path
            d="M20.184 8.635l.337-.13c.085-.35.512-.526.478-.937l.118-.119.108-.096c-.025-.078.274.043.085-.138-.037-.143-.203-.052-.267-.147-.083-.127-.12-.259-.045-.404.331-.33 1.232-.508 1.61-.316.362.184.358.782-.008 1.126-.382.164-.352.558-.477.866-.14.35-.357.61-.758.656-.128.16-.26.341-.465.11-.148-.169-.313-.124-.486-.097-.178.061-.382.21-.44-.132l.21-.242z"
            fill="#844F11"
            fillRule="nonzero"
          />

          <Path
            d="M22.426 7.463c.345-.137.376-.376.3-.714-.089-.39-.332-.227-.566-.254-.425-.047-.85-.218-1.154.252l-.125-.005c0-.239.202-.305.35-.428.127-.107.39-.244.031-.409-.125-.057-.12-.148-.015-.215.414-.267.658-.663.816-1.194.218.495.397.891.944.833l.1.131c-.04.631.333 1.233.133 1.875-.282.308-.466.7-.82.944-.255.007-.078-.222-.169-.306.072-.166.065-.359.175-.51"
            fill="#BB7A21"
            fillRule="nonzero"
          />

          <Path
            d="M14.517 11.348c.24-.094.391-.35.672-.365.25-.1.514-.012.766-.06.18-.03.303.08.437.165.21.36.671.534.726 1.005-.103.084-.195.195-.353.13a.358.358 0 01-.13-.336c-.049.158.074.292.066.445-.05.227-.243.296-.422.38-.18.044-.343-.013-.503-.09l-.172-.187c-.058-.163-.052-.332-.043-.5l.026-.159.056-.14c.064-.095.1-.179-.075-.182l-.138-.024c-.235-.074-.35.09-.475.236-.185.151-.265.377-.406.56-.262.161.07.366-.038.54-.128.125-.262.09-.397.017-.166-.123-.3-.266-.336-.479-.125-.278.123-.366.261-.51l.223-.216.255-.23z"
            fill="#0B0C06"
            fillRule="nonzero"
          />

          <Path
            d="M12.495 10.08c-.554-.353-.074-.76-.028-1.087.073-.526.438-1.014.69-1.512.042-.084.147-.155.241-.063.14.135.161.287.07.476-.245.505-.516 1.005-.68 1.538-.073.235-.082.473-.293.648"
            fill="#BA7920"
            fillRule="nonzero"
          />

          <Path
            d="M23.244 10.284c.155-.066.154-.364.383-.29.084.028.173.179.179.278.025.336-.356.114-.448.306.295.234.387.85.158 1.12-.113.136-.342.094-.295-.021.148-.36-.138-.384-.303-.497-.368-.252-.218-.69-.38-1.012.14-.08.25-.007.363.065.064.358.254-.135.343.05"
            fill="#BB7C22"
            fillRule="nonzero"
          />

          <Path
            d="M11.217 4.829c.008-.36-.281-.782.22-1.038l.135-.026c.148.113.045.305.137.435l.09.064c.323.255.248.803.704.96.045.148-.032.252-.124.35l-.11.11-.352-.123c-.137-.045-.281-.082-.363-.22l-.122-.133c-.032-.15-.068-.295-.215-.38"
            fill="#845011"
            fillRule="nonzero"
          />

          <Path
            d="M23.24 7.336c-.257-.17-.053-.451-.16-.693-.151-.342-.058-.792-.068-1.196.363.034.167.493.449.589.172.11.404.144.272.478-.103.259-.095.565-.232.826l-.26-.004z"
            fill="#C79335"
            fillRule="nonzero"
          />

          <Path
            d="M18.752 7.097l.224.113c.402.103.36-.373.61-.468h.006l.215-.024c.132.608-.187 1.19-.104 1.793-.191-.556-.914-.754-.951-1.414"
            fill="#BB7A20"
            fillRule="nonzero"
          />

          <Path
            d="M13.809 14.186l.112.584c-.062.09-.025.226-.134.294-.262.093-.51.244-.807.177l-.082-.228c.048-.232.272-.238.39-.333-.122.022-.25.134-.407.162l-.151-.044c-.14-.252.033-.357.217-.445.176-.084.356-.158.502-.292l.229.004.13.121z"
            fill="#E5BE78"
            fillRule="nonzero"
          />

          <Path
            d="M14.262 11.579l-.14.227c-.216.126-.45.232-.554.485-.383.052-.676-.157-.63-.492.042-.31.389-.383.717-.278.194.063.393.173.607.058"
            fill="#BA791F"
            fillRule="nonzero"
          />

          <Path
            d="M22.31 8.044l.11.235c-.136.356-.367.608-.696.825-.247.163-.24-.089-.359-.108a2.31 2.31 0 00.703-.716c.03-.13.023-.297.241-.236"
            fill="#BB7B21"
            fillRule="nonzero"
          />

          <Path
            d="M10.153 11.585l-.26-.01c.096-.244.198-.484.467-.594.099.05.306-.07.288.16-.022.296-.214.425-.495.444"
            fill="#DAAF61"
            fillRule="nonzero"
          />

          <Path
            d="M22.887 10.285c-.116-.04-.233-.078-.35-.117.044-.266-.045-.663.471-.417.326.155.127.277.005.418.024.11-.01.156-.126.116"
            fill="#BA7920"
            fillRule="nonzero"
          />

          <Path
            d="M16.392 11.088c-.14.063-.22-.056-.323-.104l-.14-.122c-.14-.25-.435-.065-.657-.234.175-.167.366-.084.531-.105.173.212.52.246.59.565"
            fill="#EDB555"
            fillRule="nonzero"
          />

          <Path
            d="M16.753 12.17l.365-.077c-.126.337-.337.585-.7.677l-.144.021-.03-.148c.109-.142.344-.142.393-.35l.116-.123z"
            fill="#706F5E"
            fillRule="nonzero"
          />

          <Path
            d="M15.191 12.983l-.295.007-.083-.011c-.136-.095-.168-.217-.101-.367.093-.125.214-.18.37-.15l.149.082.062.058.082.146c.016.138-.067.2-.184.235"
            fill="#CBC2B4"
            fillRule="nonzero"
          />

          <Path
            d="M10.252 10.289l.129-.003.23.115c.086.052.14.128.167.224l-.047.127-.33.125-.15-.588"
            fill="#CE8522"
            fillRule="nonzero"
          />

          <Path
            d="M14.896 12.99c-.312.372-.586.104-.866-.053l.145-.063c.262-.13.48.003.702.116h.019z"
            fill="#D09837"
            fillRule="nonzero"
          />

          <Path
            d="M13.572 10.993c.272-.032.478.133.704.238-.286.072-.62.285-.704-.238"
            fill="#E6B354"
            fillRule="nonzero"
          />

          <Path
            d="M13.568 12.29c-.038-.415.224-.488.554-.484.02.258-.386.223-.344.499l-.21-.014z"
            fill="#8A5926"
            fillRule="nonzero"
          />

          <Path
            d="M11.912 11.346c.037.68-.382.161-.581.221a.714.714 0 01.58-.22"
            fill="#C99537"
            fillRule="nonzero"
          />

          <Path
            d="M14.877 12.99c-.253.078-.46-.125-.703-.116l-.06-.091c.138-.032.305.013.398-.146-.047-.097.017-.124.093-.142l.14.152.132.343"
            fill="#1F150A"
            fillRule="nonzero"
          />

          <Path
            d="M14.98 14.174c-.234-.05-.473.155-.707-.01-.002-.072-.147-.215.063-.183.22.034.47 0 .645.193"
            fill="#C6902F"
            fillRule="nonzero"
          />

          <Path
            d="M16.28 12.999c-.318.167-.636.338-.957.007.197-.195.443-.164.686-.15a.409.409 0 01.27.143"
            fill="#705F46"
            fillRule="nonzero"
          />

          <Path
            d="M20.056 12.055c-.199-.086-.379.337-.587.023.194-.04.378-.369.587-.023"
            fill="#FAF2D2"
            fillRule="nonzero"
          />

          <Path
            d="M11.209 5.685l.353-.12c.133-.072.179.035.237.116l.043.087-.044.033c-.198-.03-.423.078-.59-.116"
            fill="#B8761C"
            fillRule="nonzero"
          />

          <Path
            d="M13.553 13.355c-.469.014-.31-.337-.337-.58l-.007-.029c.301-.019.152.253.24.371.094.053.113.139.104.238"
            fill="#E6BD72"
            fillRule="nonzero"
          />

          <Path
            d="M16.034 12.996l-.71.01-.132-.023.13-.187c.159-.154.338-.05.51-.04.106.047.249.064.202.24"
            fill="#7D7C69"
            fillRule="nonzero"
          />

          <Path
            d="M16.034 12.996l-.245-.134c.083-.286.274-.16.443-.115l.186.023c-.015.095-.032.189-.138.229l-.246-.003z"
            fill="#635F4D"
            fillRule="nonzero"
          />

          <Path
            d="M13.449 13.117l-.24-.371c.018-.097.04-.192.168-.106.193.132.189.297.072.477"
            fill="#C59130"
            fillRule="nonzero"
          />

          <Path
            d="M23.607 8.866l.125.154-.139.146c-.184-.071-.209-.25-.22-.424.095.008.185.027.234.124"
            fill="#A97425"
            fillRule="nonzero"
          />

          <Path
            d="M23.356 8.643l.005.11-.236.235h-.114c-.23-.204.079-.225.116-.338l.23-.007z"
            fill="#CA9738"
            fillRule="nonzero"
          />

          <Path
            d="M23.011 8.988h.113c-.035.207-.06.42-.382.468-.012-.233.223-.304.27-.468"
            fill="#B6721A"
            fillRule="nonzero"
          />

          <Path
            d="M21.126 12.522l-.005-.117v-.115c.188.003.377.003.47.213-.15.133-.307.103-.465.02"
            fill="#DCB871"
            fillRule="nonzero"
          />

          <Path
            d="M12.27 5.685v-.12c.13-.145.321-.049.471-.119l.228.12c-.2.223-.468.072-.7.12"
            fill="#8A5514"
            fillRule="nonzero"
          />

          <Path
            d="M23.342 8.158l.117.227c-.095.1-.197.112-.308.025-.12-.06-.07-.144-.033-.225.06-.125.145-.056.224-.027"
            fill="#CD9938"
            fillRule="nonzero"
          />

          <Path
            d="M23.15 8.41c.105-.01.207-.017.31-.025.16.164-.127.149-.104.258l-.23.007c-.151-.096-.016-.163.025-.24"
            fill="#79430B"
            fillRule="nonzero"
          />

          <Path
            d="M13.684 9.694c-.02.187.08.43-.231.477a.634.634 0 01.23-.477"
            fill="#D4A142"
            fillRule="nonzero"
          />

          <Path
            d="M10.731 10.752v-.104a.31.31 0 01.119-.247l.119-.121c-.06.167.15.469-.238.472"
            fill="#C59132"
            fillRule="nonzero"
          />

          <Path
            d="M10.747 11.948c-.03.21-.147.329-.361.349-.003-.244.197-.278.36-.35"
            fill="#D6A752"
            fillRule="nonzero"
          />

          <Path
            d="M13.678 14.065l-.23-.004c.02-.162-.061-.35.127-.468l.103.472"
            fill="#DDB265"
            fillRule="nonzero"
          />

          <Path
            d="M19.973 8.878c.104.19.294.089.441.132l-.002.097a.474.474 0 01-.467 0c-.129-.092-.127-.17.028-.23"
            fill="#C28F33"
            fillRule="nonzero"
          />

          <Path
            d="M21.816 12.046c-.195-.006-.392-.01-.587-.017.184-.16.401-.069.606-.084l-.02.101z"
            fill="#DEBB78"
            fillRule="nonzero"
          />

          <Path
            d="M11.798 5.681l-.237-.116-.007-.224.363.22-.119.12z"
            fill="#BA7C22"
            fillRule="nonzero"
          />

          <Path
            d="M12.745 10.992c-.085.019-.175.125-.251-.016l.206-.248.045.264z"
            fill="#EDC680"
            fillRule="nonzero"
          />

          <Path
            d="M22.887 10.285l.126-.116c.096 0 .181.022.23.115-.119-.035-.237.365-.356 0"
            fill="#CA9939"
            fillRule="nonzero"
          />

          <Path
            d="M19.945 9.108l.468-.001c-.156.23-.312.125-.468 0"
            fill="#D39F3B"
            fillRule="nonzero"
          />

          <Path
            d="M22.675 13.128c-.139-.083-.142-.169-.005-.258.34.08.071.171.005.258"
            fill="#FCF3D3"
            fillRule="nonzero"
          />

          <Path
            d="M21.816 12.046l.019-.101.12.105z"
            fill="#F2DFB2"
            fillRule="nonzero"
          />

          <Path
            d="M22.647 23.494l-.468.12c-.066-.062-.185-.016-.235-.111l.233-.474c.008-.252.13-.47.207-.711-.105.049-.195.138-.32.12-.097-.173-.32-.254-.356-.473.043-.171.317-.226.233-.461-.093-.024-.232-.04-.056-.152a5.143 5.143 0 001.729-1.83c.317-.555.985-.675 1.279-1.215l.236.005c-.104.705-.542 1.245-.914 1.816-.078.12-.307.198-.177.375.334.453.039.828-.163 1.169-.373.629-.815 1.217-1.228 1.822"
            fill="#BA7A20"
            fillRule="nonzero"
          />

          <Path
            d="M20.65 21.98c.007-.339-.362-.172-.472-.353.125-.19.356.01.495-.132l.092-.11a.292.292 0 01.36-.064l-.142.647-.097.078-.129.002-.106-.069z"
            fill="#EAD8A0"
            fillRule="nonzero"
          />

          <Path
            d="M22.063 22.44l.486-.513c.024.486-.228.776-.372 1.102-.2-.165.101-.428-.114-.59"
            fill="#D79D32"
            fillRule="nonzero"
          />

          <Path
            d="M21.944 23.503c.095.003.184.02.235.112-.182.106-.331.238-.358.463l-.347.005c.036-.291.257-.432.47-.58"
            fill="#B8771E"
            fillRule="nonzero"
          />

          <Path
            d="M22.428 16.776l.225-.124c-.009.394-.017.787-.027 1.18l-.043.02-.05-.022c-.204-.238-.057-.54-.149-.799-.023-.091-.036-.181.044-.255"
            fill="#D39528"
            fillRule="nonzero"
          />

          <Path
            d="M22.423 17.007c.213.251.07.55.108.823-.246-.005-.194.28-.349.362.021-.164-.05-.35.12-.476.152-.217-.037-.493.121-.709"
            fill="#DFB766"
            fillRule="nonzero"
          />

          <Path
            d="M21.126 21.32l-.36.065c-.152-.197.047-.253.14-.353.2 0 .21.144.22.289"
            fill="#CE8812"
            fillRule="nonzero"
          />

          <Path
            d="M25.13 18.312l-.237-.005c.117-.175.131-.382.236-.579v.584z"
            fill="#C38425"
            fillRule="nonzero"
          />

          <Path
            d="M21.94 21.503c.234.312-.108.331-.232.461-.164-.276.224-.273.233-.46"
            fill="#CE922C"
            fillRule="nonzero"
          />

          <Path
            d="M21.961 19.255a.858.858 0 01-.128-.592c.094.187.3.35.128.592"
            fill="#DAAB4F"
            fillRule="nonzero"
          />

          <Path
            d="M20.997 22.082l.122.127-.136.015-.018-.073z"
            fill="#D08E1B"
            fillRule="nonzero"
          />

          <Path
            d="M21.359 21.146l.059-.063-.012.038-.054.018z"
            fill="#F0E9BF"
            fillRule="nonzero"
          />

          <Path
            d="M21.352 21.139l-.055.062.011-.036.051-.019z"
            fill="#EEE4B6"
            fillRule="nonzero"
          />

          <Path
            d="M20.884 21.968h.099l.013.113-.004-.005-.055.055-.072-.029z"
            fill="#EAD79E"
            fillRule="nonzero"
          />

          <Path
            d="M21.002 22.204l.117.005-.123.118.004.002-.029-.084z"
            fill="#DFB969"
            fillRule="nonzero"
          />

          <Path
            d="M21 7.568c.252.182.25.38-.013.523-.194.107-.3.291-.466.414-.003-.395.114-.73.479-.937"
            fill="#B0721E"
            fillRule="nonzero"
          />

          <Path
            d="M23.46 6.036c-.201.053-.374.072-.336-.233.016-.13.017-.267-.112-.356l-.004-.117c.053-.097.14-.117.24-.118.082.15.106.35.335.366l-.122.458"
            fill="#CC9837"
            fillRule="nonzero"
          />

          <Path
            d="M20.412 5.801c.095-.227-.028-.49.119-.708.099-.024.266-.106.213.093-.06.221-.061.5-.332.615"
            fill="#BB7D23"
            fillRule="nonzero"
          />

          <Path
            d="M20.881 6.743l.124.005.114.346.028.08-.029.04c-.234-.08-.508-.137-.237-.471"
            fill="#9E6419"
            fillRule="nonzero"
          />

          <Path
            d="M23.133 3.79c.257.244-.092.476-.015.717-.259-.244.013-.478.015-.717"
            fill="#A77324"
            fillRule="nonzero"
          />

          <Path
            d="M23.583 5.578c-.383.126-.318-.156-.334-.366l.217-.008c-.124.176.073.25.117.374"
            fill="#BB7A21"
            fillRule="nonzero"
          />

          <Path
            d="M19.806 6.718l-.215.023c.01-.219.134-.319.342-.335l-.127.312"
            fill="#6C3A08"
            fillRule="nonzero"
          />

          <Path
            d="M21.119 7.213v-.12c.129-.057.243-.086.252.115l-.138.237-.116.004.002-.236z"
            fill="#BA791F"
            fillRule="nonzero"
          />

          <Path
            d="M12.741 5.446c-.123.177-.31.1-.473.119l.235-.34.238.22z"
            fill="#7C470E"
            fillRule="nonzero"
          />

          <Path
            d="M11.56 3.793l-.124-.002c.13-.198-.298-.388-.009-.59.194.116.114.31.133.474l.03.079-.03.039z"
            fill="#925C17"
            fillRule="nonzero"
          />

          <Path
            d="M11.56 3.793v-.118l.111.005c.104.194.092.389.008.586-.259-.102-.059-.321-.12-.473"
            fill="#7B470E"
            fillRule="nonzero"
          />

          <Path
            d="M11.68 4.265l-.009-.586c.21.158.095.387.128.584l-.12.002z"
            fill="#A5691A"
            fillRule="nonzero"
          />

          <Path
            d="M19.586 6.742c-.077.17-.164.339-.229.515-.196.529-.267.07-.382-.046.173-.198.302-.45.611-.47"
            fill="#7E490E"
            fillRule="nonzero"
          />

          <Path
            d="M14.156 17.96l-.007.359c-.105.04-.205.004-.306-.018-.333-.038-.631.138-.955.156-.271.002-.546.048-.806-.074l-.084-.15c.093-.183.285-.176.446-.23.18-.044.368.024.548-.026.218-.021.09-.13.05-.214-.117-.346-.41-.347-.7-.354l-.157-.188a.317.317 0 01.077-.25.639.639 0 01.344-.102c.31-.01.596.056.839.265a.373.373 0 01.124.23c-.041.133-.191.275.066.362.18.06.348.155.52.234"
            fill="#DABA6C"
            fillRule="nonzero"
          />

          <Path
            d="M15.221 16.777l.114-.001c.126.065.294.006.4.136.075.183.238.356.07.569l-.23-.115c-.223-.091-.453-.148-.697-.112-.176-.063-.106-.38-.366-.354-.192-.093-.322-.23-.324-.457.015-.126.087-.207.201-.256l.357.235c.117.173.302.256.475.355"
            fill="#F9EDCB"
            fillRule="nonzero"
          />

          <Path
            d="M14.273 16.418l.239.482c-.083.139-.219.105-.344.109l-.267-.097-.015-.14c.062-.101.14-.193.202-.293l.185-.061z"
            fill="#F6E7BE"
            fillRule="nonzero"
          />

          <Path
            d="M11.445 21.141c-.225-.122-.225-.122.115-.352a1.27 1.27 0 01.83.003l-.004.115a.69.69 0 01-.589 0l-.352.234"
            fill="#E4C87F"
            fillRule="nonzero"
          />

          <Path
            d="M14.63 21.498c-.046.142.113.313-.02.41-.044.033-.218-.11-.333-.174l-.372-.026.725-.21"
            fill="#E8D196"
            fillRule="nonzero"
          />

          <Path
            d="M17.931 22.912c-.332-.088-.284-.264-.108-.47l.192.208c.06.052.233.005.166.154l-.25.108z"
            fill="#E3C373"
            fillRule="nonzero"
          />

          <Path
            d="M14.878 17.253c.271-.207.504-.172.697.112h-.117c-.099.1-.2.191-.358.116l-.222-.228z"
            fill="#E0C788"
            fillRule="nonzero"
          />

          <Path
            d="M20.414 17.485l.685-.336c-.06.332-.292.353-.454.449-.064-.065-.182-.02-.231-.113"
            fill="#E2C170"
            fillRule="nonzero"
          />

          <Path
            d="M12.39 20.793l-.83-.003c.29-.24.634-.065.947-.12.1.174-.068.09-.117.123"
            fill="#E1BF6A"
            fillRule="nonzero"
          />

          <Path
            d="M15.1 17.481l.359-.116c-.008.172.01.398-.2.426-.162.02-.066-.22-.158-.31"
            fill="#EDD8A2"
            fillRule="nonzero"
          />

          <Path
            d="M15.804 17.481l-.108-.586.112.001c.275.149.167.375.117.59l-.12-.005z"
            fill="#EBD59C"
            fillRule="nonzero"
          />

          <Path
            d="M18.76 19.848c-.016.111-.017.23-.226.286.071-.198.093-.39.196-.517.101-.126.132.022.15.108l-.12.123z"
            fill="#E7CF91"
            fillRule="nonzero"
          />

          <Path
            d="M22.423 17.007c-.05.234.101.504-.122.71.053-.313-.128-.656.127-.94l-.005.23z"
            fill="#EAD8A0"
            fillRule="nonzero"
          />

          <Path
            d="M20.673 21.495c-.134.162-.326.104-.495.133.036-.523.351-.01.495-.133"
            fill="#D9A84B"
            fillRule="nonzero"
          />

          <Path
            d="M15.338 20.79l-.232.235-.151-.1c.067-.17.24-.128.383-.135"
            fill="#E5CB85"
            fillRule="nonzero"
          />

          <Path
            d="M11.797 20.907h.59c-.197.21-.394.15-.59 0"
            fill="#E8D196"
            fillRule="nonzero"
          />

          <Path
            d="M20.414 17.485c.095.002.182.02.23.113-.09.055-.11.144-.114.241-.097-.003-.186-.025-.237-.121l.12-.233z"
            fill="#E1BE69"
            fillRule="nonzero"
          />

          <Path
            d="M13.568 17.365l-.115-.115c.036-.132.134-.193.258-.222a.345.345 0 01.328.103l-.47.234"
            fill="#EAD192"
            fillRule="nonzero"
          />

          <Path
            d="M14.04 17.13c-.12 0-.239-.002-.357-.004l-.036-.128.153-.15.127.042.24.118-.128.122z"
            fill="#D8C388"
            fillRule="nonzero"
          />

          <Path
            d="M21.243 16.178c.156-.156.31-.156.464.003-.154.016-.31.128-.464-.003"
            fill="#FDF2D2"
            fillRule="nonzero"
          />

          <Path
            d="M21.002 22.204l-.002.125c-.093-.004-.185-.012-.226-.116l.106-.13.113-.006.009.127z"
            fill="#D7A443"
            fillRule="nonzero"
          />

          <Path
            d="M20.879 22.084l-.105.128-.125-.129.118-.051.117.056z"
            fill="#EDE2B2"
            fillRule="nonzero"
          />

          <Path
            d="M20.765 22.086l-.059.002-.057-.006.002-.103.114-.01.031.078z"
            fill="#D8A342"
            fillRule="nonzero"
          />

          <Path
            d="M8.844 19.374c-.523-.238-1.105-.403-1.305-1.045l-.03-.097.023-.05c.247-.067.402.119.57.229.414.273.906.312 1.349.49.084.166.026.277-.136.344l.003.005c-.144.098-.293.17-.474.124"
            fill="#B6985F"
            fillRule="nonzero"
          />

          <Path
            d="M12.98 18.322c.32-.06.616-.307.969-.131-.26.508-.799.375-1.212.528-.275.026-.554-.044-.825.043l-.467.166-.079-.027c-.118-.087-.207-.19-.185-.351l.135-.103c.254-.063.506-.146.773-.077.296-.025.598.057.89-.048"
            fill="#1B1815"
            fillRule="nonzero"
          />

          <Path
            d="M12.75 18.671c.399-.158.852-.187 1.199-.48l.2.127c-.355.531-.928.37-1.424.453l-.016-.075.04-.025z"
            fill="#BA9C61"
            fillRule="nonzero"
          />

          <Path
            d="M10.38 19.142c-.028.24.061.49-.08.77-.286-.306-.029-.858-.51-1.009.115-.133.26-.129.414-.092l.203.168-.028.163z"
            fill="#DCBC6C"
            fillRule="nonzero"
          />

          <Path
            d="M10.38 19.142l-.01-.1c.083-.13.215-.156.353-.17.24.004.488-.01.604.27a.373.373 0 01-.237.227l-.219-.012c-.164-.07-.328-.144-.492-.215"
            fill="#B0935C"
            fillRule="nonzero"
          />

          <Path
            d="M9.315 19.245l.129-.328.045-.056.138.002.04.059v1.026c-.166-.29-.065-.604-.352-.703"
            fill="#D8B86A"
            fillRule="nonzero"
          />

          <Path
            d="M10.615 23.386c-.357-.017-.732.146-1.064-.112.375-.154.728-.103 1.064.112"
            fill="#CFA04C"
            fillRule="nonzero"
          />

          <Path
            d="M8.16 22.68c-.025-.385-.513-.384-.598-.719l.6.318c.262.136.102.268-.002.401"
            fill="#E3B153"
            fillRule="nonzero"
          />

          <Path
            d="M7.077 19.49l.353-.113c-.03.22.026.51-.354.467-.088-.12-.08-.236 0-.353"
            fill="#E1BE69"
            fillRule="nonzero"
          />

          <Path
            d="M12.503 23.376l-.59.012c.131-.374.356-.274.592-.118l.03.072-.032.034z"
            fill="#E7C978"
            fillRule="nonzero"
          />

          <Path
            d="M11.327 19.141c-.183-.11-.39-.109-.592-.128v-.154c.247-.222.48-.089.713.032l-.003.037c.013.1-.047.16-.118.213"
            fill="#55483E"
            fillRule="nonzero"
          />

          <Path
            d="M13.45 23.392c-.095.207-.273.24-.473.23l-.054-.208.174-.143.352.121"
            fill="#C6892F"
            fillRule="nonzero"
          />

          <Path
            d="M8.844 19.374l.474-.123c-.131.268-.347.28-.593.195l.014-.042.105-.03z"
            fill="#E9C872"
            fillRule="nonzero"
          />

          <Path
            d="M14.995 23.047c-.138.15-.314.097-.481.092.1-.34.28-.268.48-.092"
            fill="#E7CA7A"
            fillRule="nonzero"
          />

          <Path
            d="M13.097 23.271l-.122.115c-.1.088-.199.18-.35.12l-.122-.13.003-.107.591.002"
            fill="#DEAD51"
            fillRule="nonzero"
          />

          <Path
            d="M12.75 18.671l-.025.1-.813-.009c.264-.166.556-.08.837-.09"
            fill="#524530"
            fillRule="nonzero"
          />

          <Path
            d="M7.545 18.206l-.006.122-.226-.018-.103-.242c.075-.088.022-.312.252-.236.093.11.184.22.083.374"
            fill="#B99A5E"
            fillRule="nonzero"
          />

          <Path
            d="M11.557 23.51c-.162.16-.32.158-.473-.015.162-.16.315.008.473.014"
            fill="#B47017"
            fillRule="nonzero"
          />

          <Path
            d="M7.077 19.49l-.001.354-.353.12c.064-.164.25-.268.233-.472l.12-.001z"
            fill="#EDDBA4"
            fillRule="nonzero"
          />

          <Path
            d="M12.23 19.084c.144-.071.26-.161.433-.12-.102.209-.248.193-.433.12"
            fill="#E8C672"
            fillRule="nonzero"
          />

          <Path
            d="M10.253 18.94l-.462-.037-.124.018.02-.055c.013-.183.155-.183.283-.203.154.031.348.021.283.276"
            fill="#685844"
            fillRule="nonzero"
          />

          <Path
            d="M10.871 19.357c.075-.022.152-.104.22.012-.09.13-.242.175-.34.086l.12-.098z"
            fill="#E9C772"
            fillRule="nonzero"
          />

          <Path
            d="M14.394 23.15c-.067.123-.145.03-.22.011l-.143-.067c.14-.111.259-.07.363.056"
            fill="#E7C775"
            fillRule="nonzero"
          />

          <Path
            d="M12.744 25.626l-.238-.124.26-.436c-.013.274.171.412-.022.56"
            fill="#BD7D23"
            fillRule="nonzero"
          />

          <Path
            d="M12.625 23.506l.351-.12.001.234c-.128-.004-.267.023-.352-.114"
            fill="#B47118"
            fillRule="nonzero"
          />

          <Path
            d="M9.78 13.833l.47.232c.214.062.39.179.532.35l.035.162c-.064.394-.248.737-.45 1.073a.717.717 0 01-.259.205 7.13 7.13 0 00-.967.707c-.123.087-.258.156-.384.237-.102.04-.194 0-.287-.03-.167-.043-.35-.033-.49-.156l-.185-.144c-.289-.253-.543-.532-.683-.898-.026-.138-.018-.266.107-.355l.126-.028-.107-.014a.358.358 0 01-.24-.19.777.777 0 01-.066-.263c-.02-.296.014-.574.272-.772l.226-.122c.386.143.805-.09 1.187.1.345.169.652.086.94-.138l.222.044z"
            fill="#030302"
            fillRule="nonzero"
          />

          <Path
            d="M8.847 12.408l.002.099c-.208.134-.341.367-.59.448-.236-.022-.441-.103-.575-.314-.098-.402.018-.786.096-1.173-.062-.182.059-.247.194-.303a.518.518 0 01.318.066l.129.095.186.249c.169.26.526.15.718.364l-.006.112c-.16.117-.383.149-.472.357"
            fill="#E4B153"
            fillRule="nonzero"
          />

          <Path
            d="M9.78 13.833l-.168.006c-.299.23-.65.224-1 .22-.247-.051-.479-.12-.744-.028-.152.053-.415.122-.438-.204l.002-.111c.141-.083.25-.266.455-.15l.136.029c.35.096.7.078 1.051.008.251.027.495.078.705.23"
            fill="#6C655C"
            fillRule="nonzero"
          />

          <Path
            d="M7.888 13.567l-.455.148c.118-.231.16-.466.057-.715-.084-.2-.048-.335.194-.359l.574.255-.001.224c-.06.2-.343.218-.37.447"
            fill="#F0E0AD"
            fillRule="nonzero"
          />

          <Path
            d="M6.016 14.412l-.002-.233.59-.12.236.002.048.222c-.058.099-.152.152-.248.203-.123.052-.243.122-.383.058-.05-.101-.14-.126-.24-.132"
            fill="#F1E1AD"
            fillRule="nonzero"
          />

          <Path
            d="M6.826 14.286l.014-.225.364-.112c-.025.278-.213.527-.152.82l-.14-.02c-.094-.142-.1-.301-.086-.463"
            fill="#EDECE8"
            fillRule="nonzero"
          />

          <Path
            d="M8.013 11.22a.428.428 0 00-.233.247c-.16-.129-.1-.31-.114-.474l.36.006-.013.22z"
            fill="#EACD89"
            fillRule="nonzero"
          />

          <Path
            d="M9.325 11.94c-.328-.003-.704.106-.833-.357l.146-.163c.129-.015.255.02.383.031.18.028.366.034.536.11l.246.03c-.012.32-.342.2-.478.348"
            fill="#4F524A"
            fillRule="nonzero"
          />

          <Path
            d="M9.557 11.561c-.197.134-.403-.019-.604.02-.258-.323-.3-.67-.127-1.044l.023-.158.114-.122.08-.016.1.118c-.023.43.133.79.412 1.106l.002.096z"
            fill="#111113"
            fillRule="nonzero"
          />

          <Path
            d="M9.555 11.465c-.645-.19-.415-.742-.48-1.17.126-.052.158.004.134.121-.05.404.446.628.346 1.05"
            fill="#1C1C1F"
            fillRule="nonzero"
          />

          <Path
            d="M9.209 10.417l-.134-.122-.091-.01-.053-.071.03-.047h.73c-.11.243-.305.228-.482.25"
            fill="#1D1D22"
            fillRule="nonzero"
          />

          <Path
            d="M8.961 10.167l.022.119-.026.003-.048.033-.056-.01.001-.065z"
            fill="#33250F"
            fillRule="nonzero"
          />

          <Path
            d="M8.36 11.355l-.103-.011c-.118-.159-.099-.32-.013-.485.023-.084.033-.177.137-.208l.01.13c.041.194.105.389-.032.574"
            fill="#A8956D"
            fillRule="nonzero"
          />

          <Path
            d="M8.244 10.859l.014.485-.245-.125.012-.219.22-.141z"
            fill="#F0C167"
            fillRule="nonzero"
          />

          <Path
            d="M10.85 10.4c0 .103-.025.192-.119.248-.09-.058-.113-.148-.12-.247l.117-.122.122.122z"
            fill="#D7A143"
            fillRule="nonzero"
          />

          <Path
            d="M8.825 10.537l.127 1.043-.326-.143-.036-.202c.121-.22-.203-.587.235-.698"
            fill="#FDFCF8"
            fillRule="nonzero"
          />

          <Path
            d="M8.59 11.235l.036.202-.135.146-.131-.228.031-.574c.179.1.022.35.199.454"
            fill="#41473E"
            fillRule="nonzero"
          />

          <Path
            d="M8.853 10.311l.104-.022-.109.09z"
            fill="#F5ECDF"
            fillRule="nonzero"
          />

          <Path
            d="M7.545 18.206l-.123-.365.028-.227.099-.012c.71.013 1.36.216 1.952.609.109.236.118.462-.071.666l.014.04c-.732.029-1.332-.3-1.9-.711"
            fill="#5A4D40"
            fillRule="nonzero"
          />

          <Path
            d="M9.437 18.192c-.64-.165-1.28-.326-1.887-.591-.003-.108.075-.162.15-.216.1-.145.247-.136.397-.12.187.055.379.079.574.076l.164.011a.899.899 0 01.373.142l.194.116c.154.08.327.096.49.148.093.035.18.08.269.124.132.069.265.133.409.174l.216.083c.142.133.42.123.426.402l-.006.002c-.134.252-.332.134-.517.09-.264-.082-.536-.139-.8-.226-.158-.056-.33-.083-.452-.215"
            fill="#0A0907"
            fillRule="nonzero"
          />

          <Path
            d="M8.087 17.38l-.387.005c-.125-.029-.301-.03-.312-.183-.011-.16.149-.242.282-.312l.149-.029c.275.197.594.194.911.2.21.088.25.223.116.41l-.121.002c-.201-.11-.435.003-.638-.094"
            fill="#978E78"
            fillRule="nonzero"
          />

          <Path
            d="M7.78 16.885l-.11.005-.121-.114-.043-.226c.1-.13.237-.127.379-.107l.139.088c.07.103.008.181-.05.261l-.194.093z"
            fill="#6D6755"
            fillRule="nonzero"
          />

          <Path
            d="M7.45 17.614l-.028.227-.213.227-.025-.473.132-.115.134.134z"
            fill="#B9995C"
            fillRule="nonzero"
          />

          <Path
            d="M7.558 16.543l-.01.233-.576.119-.011-.123c.1-.174.26-.257.453-.283l.144.054z"
            fill="#9D947E"
            fillRule="nonzero"
          />

          <Path
            d="M6.237 15.716l.013-.12.1-.116c.311-.157.411.07.518.284l-.03.166-.137.13c-.152-.11-.298.012-.448.008l-.016-.352z"
            fill="#F5F5F6"
            fillRule="nonzero"
          />

          <Path
            d="M7.433 16.542l-.472.229a.98.98 0 01.107-.592c-.008-.12.058-.148.161-.132.18.119.351.24.204.495"
            fill="#857C69"
            fillRule="nonzero"
          />

          <Path
            d="M7.19 16.062l-.122.116c-.169.003-.22-.102-.232-.248l.005-.097.142-.039c.114.055.255.09.207.268"
            fill="#C2BDB0"
            fillRule="nonzero"
          />

          <Path
            d="M11.217 4.829c.436-.08.333.146.215.379-.16-.076-.235-.201-.215-.38"
            fill="#C79335"
            fillRule="nonzero"
          />

          <Path
            d="M6.948 15.825l-.106.008c-.163-.12-.217-.39-.491-.353l-.125-.117.026-.24c.1-.112.278-.097.374-.22l.118-.087.213-.046h.095l.033.212c.113.24.05.479-.006.717l-.131.126z"
            fill="#F1EEEA"
            fillRule="nonzero"
          />

          <Path
            d="M6.723 14.888c-.007.38-.256.273-.472.235l-.008-.355.021.012c.182-.084.33-.03.46.108"
            fill="#F6F6F5"
            fillRule="nonzero"
          />

          <Path
            d="M5.896 15.24l-.707-.002c.236-.172.472-.176.707.003"
            fill="#E9DAA8"
            fillRule="nonzero"
          />

          <Path
            d="M6.723 14.888a.786.786 0 01-.459-.108l-.007-.237.344-.132c.224.086.351.221.237.475l-.115.002z"
            fill="#F2F2EF"
            fillRule="nonzero"
          />

          <Path
            d="M13.802 15.006c.008-.095.023-.186.12-.236.28.245.63.035.952.151l-.245.202c-.197.15-.442.217-.628.385-.238-.1-.329-.258-.199-.502"
            fill="#F0E5C3"
            fillRule="nonzero"
          />

          <Path
            d="M14.031 15.49c-.019-.48.337-.345.598-.366v.236c-.062.219.21.105.236.235-.085.14-.225.115-.355.12-.18.143-.352.152-.506-.041l.027-.184z"
            fill="#FBEFCC"
            fillRule="nonzero"
          />

          <Path
            d="M14.392 15.95l-.12.119c-.096.115-.109.278-.226.382-.228.01-.266-.22-.405-.32-.072-.085-.125-.177-.12-.293.033-.14.1-.253.244-.304l.15.023c.027.29.371.198.477.393"
            fill="#EFDFB5"
            fillRule="nonzero"
          />

          <Path
            d="M14.392 15.95c-.156-.114-.525.062-.469-.343l.103-.031c.12.188.314.124.485.139.19.223.477.048.695.235h-.814z"
            fill="#F1E4C1"
            fillRule="nonzero"
          />

          <Path
            d="M14.865 15.595h-.663c.075-.327.29-.193.427-.235.153.004.232.082.236.235"
            fill="#F7EAC7"
            fillRule="nonzero"
          />

          <Path
            d="M15.221 16.777c-.315.092-.523.039-.475-.355.251-.006.464.04.475.355"
            fill="#E8D299"
            fillRule="nonzero"
          />

          <Path
            d="M19.558 12.808c-.217.238-.39.224-.612.074.187-.19.367-.11.612-.074"
            fill="#EBD69D"
            fillRule="nonzero"
          />

          <Path
            d="M14.034 16.424c.006-.167-.01-.349.237-.355l.118.118-.116.23-.116.117c-.11.038-.141-.007-.123-.11"
            fill="#E0C786"
            fillRule="nonzero"
          />

          <Path
            d="M15.808 16.896l-.112-.001c-.132-.006-.273.021-.36-.119.193-.101.366-.121.472.12"
            fill="#F5E7BE"
            fillRule="nonzero"
          />

          <Path
            d="M21.126 12.522l.465-.019c-.15.16-.301.244-.465.02"
            fill="#FEFBE0"
            fillRule="nonzero"
          />

          <Path
            d="M10.718 14.54l-.035-.105.054-.123.105.014c.607-.061.88.435 1.259.756l.039.244-.047.158-.181.155c-.143.026-.305-.011-.414.128l-.192.135-.241.009c-.143-.051-.211-.166-.258-.3-.101-.35-.002-.717-.09-1.07"
            fill="#F5F4F1"
            fillRule="nonzero"
          />

          <Path
            d="M12.046 15.127c-.429-.226-.7-.688-1.205-.801.576-.378 1.052-.02 1.546.207l.047.136c-.058.169-.125.333-.25.466l-.138-.008z"
            fill="#EFDFAC"
            fillRule="nonzero"
          />

          <Path
            d="M12.39 14.649l-.002-.116c.357-.148.67-.4 1.061-.472-.049.424-.716.217-.71.695-.155.086-.259.013-.349-.107"
            fill="#DBB673"
            fillRule="nonzero"
          />

          <Path
            d="M9.075 13.602c-.352.162-.702.174-1.051-.008.35-.053.7-.05 1.05.008"
            fill="#565457"
            fillRule="nonzero"
          />

          <Path
            d="M8.256 13.12l.001-.224c.224-.09.318-.376.591-.39-.137.262-.35.452-.592.614"
            fill="#F0E4B6"
            fillRule="nonzero"
          />

          <Path
            d="M8.847 12.408c.022-.297.216-.367.472-.357-.074.229-.304.253-.472.357"
            fill="#EFE0AD"
            fillRule="nonzero"
          />

          <Path
            d="M10.736 14.312l-.053.122-.433-.369c.213-.018.396.022.486.247"
            fill="#FEE891"
            fillRule="nonzero"
          />

          <Path
            d="M15.928 10.863l.141.122-.88-.002c.222-.186.486-.12.74-.12"
            fill="#0F0A06"
            fillRule="nonzero"
          />

          <Path
            d="M22.31 8.044l-.242.236c-.175-.36.283-.528.262-.842l.051-.01.045.034c.008.204-.019.4-.117.582"
            fill="#804C10"
            fillRule="nonzero"
          />

          <Path
            d="M21.233 7.446c.008-.102.039-.188.138-.236.109-.025.256-.188.314.026.057.202-.091.19-.238.163l-.214.047z"
            fill="#A4681A"
            fillRule="nonzero"
          />

          <Path
            d="M14.744 12.647l-.135-.116c-.147-.086-.209-.197-.115-.36.094-.22.136-.477.398-.584.156-.024.247.06.308.19.064.261.106.52-.08.755l-.376.115"
            fill="#DDD5CA"
            fillRule="nonzero"
          />

          <Path
            d="M15.69 12.412l.116.117c-.166.267-.213.266-.535-.012-.006-.277-.247-.634.251-.742.236.164.005.452.168.637"
            fill="#1D1D22"
            fillRule="nonzero"
          />

          <Path
            d="M15.344 12.52c.153.067.304.227.462.01l.438.113-.012.104-.444.115-.467-.067-.097-.153.12-.122z"
            fill="#0D0D08"
            fillRule="nonzero"
          />

          <Path
            d="M15.344 12.52l-.12.122-.018.012c-.094-.285-.022-.578-.045-.867l.022-.193c.204-.156.361-.068.502.1l.006.125-.12.003c-.426.12-.155.465-.227.698"
            fill="#030306"
            fillRule="nonzero"
          />

          <Path
            d="M15.228 11.607l-.009.094-.061.028-.266-.142c.128-.219.255-.44.562-.237.071.215-.197.131-.226.257"
            fill="#DBD8D0"
            fillRule="nonzero"
          />

          <Path
            d="M14.494 12.171l.115.36-.097.106c-.117-.075-.322-.073-.31-.264.01-.147.153-.192.292-.202"
            fill="#1F1E17"
            fillRule="nonzero"
          />

          <Path
            d="M15.228 11.607l.226-.257.098.005c.135.08.276.157.127.342l.006-.003c-.17.064-.279-.193-.457-.087"
            fill="#CECECF"
            fillRule="nonzero"
          />

          <Path
            d="M15.68 11.697c.086-.162-.04-.245-.128-.342h.384a.564.564 0 01-.257.342m1.074.473l-.116.123c-.021-.13-.234-.276-.032-.376.099-.05.134.142.148.253m-1.183-.348l.121-.003v.593c-.195-.166-.089-.392-.12-.59"
            fill="#0D0D08"
            fillRule="nonzero"
          />

          <Path
            d="M14.026 15.576l-.103.03-.122-.014c-.202-.075-.383.076-.581.054l-.2-.107-.074-.224.035-.072c.256-.141.512-.281.821-.237a.853.853 0 00.229.484l-.005.086z"
            fill="#E1BB77"
            fillRule="nonzero"
          />

          <Path
            d="M12.39 14.649l.348.108.108.022.068.22c-.009.09-.053.153-.143.18-.208 0-.428.126-.62-.054.02-.19.083-.356.24-.476"
            fill="#EEDFAC"
            fillRule="nonzero"
          />

          <Path
            d="M12.868 15.002l-.021-.224c.25-.124.493-.277.818-.281-.121.375-.554.238-.692.516l-.105-.011z"
            fill="#EBC27A"
            fillRule="nonzero"
          />

          <Path
            d="M12.868 15.002l.105.01.008.23h-.01c-.132.22-.258.175-.381-.01.007-.093.035-.163.15-.11l.128-.12z"
            fill="#D8CA9E"
            fillRule="nonzero"
          />

          <Path
            d="M15.158 11.73l.062-.029c-.005.318-.01.635-.013.953l-.088-.122c-.012-.269-.065-.54.039-.803"
            fill="#575656"
            fillRule="nonzero"
          />

          <Path
            d="M20.765 22.086l.001-.117.118-.001v.118z"
            fill="#DFB969"
            fillRule="nonzero"
          />

          <Path
            d="M11.212 18.54c-.104-.192-.434-.062-.483-.335-.087-.207.075-.38.08-.573l.109-.107.152-.04.158-.007.158.01.214.099c.091.154.204.29.356.392l.077.214.004.129.022.073-.051.015c-.26.076-.51.213-.796.13"
            fill="#E0BF6F"
            fillRule="nonzero"
          />

          <Path
            d="M13.095 17.72c.02.148.314.051.246.242-.052.146-.23.105-.362.112-.162.093-.261-.148-.42-.079l-.131-.06c-.094-.107-.17-.237-.328-.265a.657.657 0 01-.277-.118l-.063-.14c.04-.148.14-.227.289-.252l.22.088.118.118c.295 0 .539.103.708.354"
            fill="#E0C070"
            fillRule="nonzero"
          />

          <Path
            d="M13.685 17.012l-.002.114-.23.123c-.26-.133-.517-.284-.83-.237-.1-.144-.04-.233.098-.298.352 0 .693.035.964.298"
            fill="#E3C270"
            fillRule="nonzero"
          />

          <Path
            d="M13.095 17.72l-.708-.354c.398-.208.625-.07.708.354"
            fill="#D5B669"
            fillRule="nonzero"
          />

          <Path
            d="M12.74 16.776c-.094.052-.116.139-.118.236h-.352c-.123-.05-.179-.132-.143-.267.084-.137.212-.19.365-.198l.246.075.002.154z"
            fill="#D5B76B"
            fillRule="nonzero"
          />

          <Path
            d="M12.008 18.41l.029-.088c.314-.031.628-.064.942 0-.311.17-.645.089-.971.088"
            fill="#524530"
            fillRule="nonzero"
          />

          <Path
            d="M12.149 16.79l.122.223-.002.236-.264-.004-.077-.16c.014-.144-.014-.318.22-.295"
            fill="#D1AE56"
            fillRule="nonzero"
          />

          <Path
            d="M12.504 17.957c.206-.152.322.055.474.118-.156.213-.314-.043-.472.002l-.002-.12z"
            fill="#E8C573"
            fillRule="nonzero"
          />

          <Path
            d="M12.504 17.957l.002.12c-.127.164-.312.095-.474.116l-.118-.12.112-.088c.114-.037.232-.058.35-.084l.127.05.001.006z"
            fill="#EECC74"
            fillRule="nonzero"
          />

          <Path
            d="M14.034 16.424l.123.11c-.002.158-.084.237-.24.244-.218.056-.2-.155-.285-.25l-.102-.193-.005-.143.168-.122c.258-.02.187.275.34.354"
            fill="#FEF5D7"
            fillRule="nonzero"
          />

          <Path
            d="M13.69 16.416c.075.12.151.242.228.362l.01.113-.132.007-.166-.032c-.077-.163-.08-.316.06-.45"
            fill="#EBD190"
            fillRule="nonzero"
          />

          <Path
            d="M13.688 16.891l.108.007-.11.114a3.31 3.31 0 01-.946-.236l.001-.118c.381-.188.691-.087.947.233"
            fill="#CBAE64"
            fillRule="nonzero"
          />

          <Path
            d="M10.734 18.55c.157.005.316.128.471-.008l.243.35c-.237.022-.477-.094-.714.007-.1-.117-.1-.234 0-.35"
            fill="#5C4F42"
            fillRule="nonzero"
          />

          <Path
            d="M10.734 18.55v.35l.001.113-.365.028-.117-.102c-.061-.185-.228-.15-.366-.176-.124-.084-.404.036-.37-.246.028-.22.214-.239.4-.215.283.045.565.098.817.247"
            fill="#5A4D40"
            fillRule="nonzero"
          />

          <Path
            d="M9.917 18.302c-.346.133-.415.282-.03.461l-.2.102-.257.011.007-.684c.168.001.344-.032.48.11"
            fill="#493F38"
            fillRule="nonzero"
          />

          <Path
            d="M9.081 16.538c.188-.421.644-.526.951-.808.253.107.086.427.286.56-.002.152-.112.226-.218.302l-.123.096c-.176.15-.22.437-.496.483-.138-.07-.306.044-.438-.072-.12-.197-.11-.384.038-.561"
            fill="#9B927C"
            fillRule="nonzero"
          />

          <Path
            d="M10.718 14.54c.297.32.133.716.172 1.077l-.11.088a.522.522 0 01-.274.056.354.354 0 01-.221-.166l.433-1.054"
            fill="#B3AD9D"
            fillRule="nonzero"
          />

          <Path
            d="M9.081 16.538c.028.197-.065.415.101.588.17.182.17.365.007.552-.1-.093-.175-.227-.343-.207.003-.17-.005-.332-.236-.34-.076-.156.011-.258.12-.352-.009-.263.184-.232.351-.241"
            fill="#948B76"
            fillRule="nonzero"
          />

          <Path
            d="M6.948 15.825l.014-.093.229-.136.694.847c-.088.102-.207.103-.327.1l-.125-.001c.018-.21-.177-.312-.242-.48l-.243-.237z"
            fill="#958D76"
            fillRule="nonzero"
          />

          <Path
            d="M8.73 16.779l-.12.352c-.328.09-.648.156-.83-.246l.12-.122c.192-.065.426.088.593-.112l.236.128z"
            fill="#948B76"
            fillRule="nonzero"
          />

          <Path
            d="M10.285 15.594c.092.002.17.03.213.12.093.078.217.121.27.243.019.172-.086.287-.183.408-.127.113-.234.107-.316-.052-.221-.137-.1-.412-.237-.583l.253-.136z"
            fill="#A5A092"
            fillRule="nonzero"
          />

          <Path
            d="M8.611 14.06c.308-.189.673-.117 1-.222-.256.423-.61.398-1 .221"
            fill="#736B63"
            fillRule="nonzero"
          />

          <Path
            d="M8.493 16.651c-.127.414-.37.209-.594.112l.124-.232c.128.154.318.062.47.12"
            fill="#7C7563"
            fillRule="nonzero"
          />

          <Path
            d="M9.586 15.525c-.354.252-.492.061-.694-.07.244-.117.4.053.694.07"
            fill="#4E4B3F"
            fillRule="nonzero"
          />

          <Path
            d="M7.191 15.596l-.229.136c.128-.236.116-.494.123-.75l.213.167.04.048-.027.032c-.177.077-.131.227-.12.367"
            fill="#EEEDE9"
            fillRule="nonzero"
          />

          <Path
            d="M7.31 15.23l-.012-.08c.156-.028.31-.049.44.141-.17.085-.306.116-.427-.062"
            fill="#A29881"
            fillRule="nonzero"
          />

          <Path
            d="M6.838 14.886c.02-.207-.172-.31-.237-.475.044-.098.134-.11.225-.126.185.123.108.318.131.485l-.119.116z"
            fill="#F4F4F4"
            fillRule="nonzero"
          />

          <Path
            d="M9.44 17.128c.283-.09.226-.48.505-.574l.147.159.111.188c.036.129-.004.24-.08.342l-.092.117c-.094.116-.032.25-.051.374l-.083.124-.58-.134c-.088-.226-.017-.418.123-.596"
            fill="#908671"
            fillRule="nonzero"
          />

          <Path
            d="M10.855 17.6c.208.253-.033.414-.126.604l-.225-.033c-.184-.225-.114-.44.023-.656l.145-.096.17.03.037.097-.024.054z"
            fill="#DEBD6C"
            fillRule="nonzero"
          />

          <Path
            d="M10.62 17.489c-.029.229-.182.437-.117.683l-.378-.195.017-.07c.064-.108.065-.23.063-.35l.057-.14.208-.09.15.162z"
            fill="#9D9279"
            fillRule="nonzero"
          />

          <Path
            d="M9.44 17.128l-.123.596-.127-.046-.008-.552c.023-.09.023-.216.158-.183l.1.185z"
            fill="#7F7765"
            fillRule="nonzero"
          />

          <Path
            d="M9.897 17.858l.014-.141c.069-.148-.05-.342.113-.467l.009-.001c.187-.03.265.047.23.235l-.001.003c-.07.146.048.339-.112.464l-.024.026-.23-.119z"
            fill="#9D9279"
            fillRule="nonzero"
          />

          <Path
            d="M8.087 17.38c.215.01.448-.094.638.093l-.638-.094"
            fill="#948B76"
            fillRule="nonzero"
          />

          <Path
            d="M13.693 16.07l-.12.118c-.325.224-.631.18-.922-.067-.095-.148-.02-.252.088-.347.152-.097.32-.071.485-.064.138-.013.263.012.357.126l.112.235z"
            fill="#DDBD70"
            fillRule="nonzero"
          />

          <Path
            d="M13.58 15.836c-.127-.005-.256-.01-.384-.013l.009-.21c.192-.198.392-.19.597-.02l-.221.243z"
            fill="#E4BD76"
            fillRule="nonzero"
          />

          <Path
            d="M10.497 16.306l.247-.355.122-.062c.08.007.17.048.222-.058h.24c.126-.062.2.008.264.105.033.185-.092.297-.196.418a1.267 1.267 0 01-.303.247l-.226.035-.14-.049c-.108-.069-.147-.193-.23-.28"
            fill="#F4F4F4"
            fillRule="nonzero"
          />

          <Path
            d="M11.915 15.6l.123-.123c.189-.083.347-.019.49.108.048.167-.015.298-.134.411-.183.092-.356.094-.511-.06l-.05-.176.082-.16z"
            fill="#ECEAE3"
            fillRule="nonzero"
          />

          <Path
            d="M12.74 15.123l-.117.118.04.095-.021.053c-.211.17-.412.13-.61-.03l.015-.232.104-.002c.197.082.393-.211.59-.002"
            fill="#E6D7A6"
            fillRule="nonzero"
          />

          <Path
            d="M12.033 15.36c.196.056.393.055.59 0 .1.049.17.115.14.24l-.137.067-.137-.064a.788.788 0 01-.452-.126l-.004-.117z"
            fill="#DACB99"
            fillRule="nonzero"
          />

          <Path
            d="M11.088 15.832c.021.1.167.215.017.29l-.262-.178c-.087-.118-.086-.234.008-.35l.039.023.198.215z"
            fill="#ECEBE8"
            fillRule="nonzero"
          />

          <Path
            d="M11.916 15.71l-.006.122c-.094.106-.192.2-.35.118l-.232-.12.11-.113c.159-.134.318-.107.478-.007"
            fill="#FDFDFD"
            fillRule="nonzero"
          />

          <Path
            d="M11.916 15.71l-.477.007c.072-.394.29-.191.476-.117v.11z"
            fill="#E4E4E5"
            fillRule="nonzero"
          />

          <Path
            d="M12.625 15.597l.115-.002c.092-.104.193-.179.34-.105l.125.123-.01.21-.454.005c-.106-.044-.175-.105-.116-.23"
            fill="#EEE4B6"
            fillRule="nonzero"
          />

          <Path
            d="M13.08 15.49l-.34.105-.118-.235.001-.12c.116.073.232.079.348.002l.108.248z"
            fill="#E3D5A5"
            fillRule="nonzero"
          />

          <Path
            d="M12.032 18.074h-.118c-.236-.07-.41-.187-.355-.476l.024-.138.21.019.008.012c.125.176.416.285.231.583"
            fill="#DDBD6C"
            fillRule="nonzero"
          />

          <Path
            d="M10.855 17.6l-.002-.128c-.032-.09.02-.133.093-.164l.14.023.043.131-.158.138h-.116z"
            fill="#CFB87D"
            fillRule="nonzero"
          />

          <Path
            d="M11.559 17.486v.112l-.232.003-.029-.096.029-.049.164-.02z"
            fill="#C6A249"
            fillRule="nonzero"
          />

          <Path
            d="M11.089 17.489l-.004-.102.123-.044.057.127-.058.13-.116-.002-.032-.072z"
            fill="#DAB356"
            fillRule="nonzero"
          />

          <Path
            d="M11.207 17.6v-.108l.124.002-.004.107z"
            fill="#EFC763"
            fillRule="nonzero"
          />

          <Path
            d="M11.089 17.489l.002.11-.121.001z"
            fill="#84724F"
            fillRule="nonzero"
          />

          <Path
            d="M11.202 17.391l-.117-.004.01-.04a.66.66 0 01-.246-.33c-.042-.159-.135-.319.004-.477h.22l.232-.058c.109.044.17.13.21.235.035.141.125.215.27.225.11.014.188.068.215.181l.005.122-.212.234-.234.007h-.001c-.097-.115-.248-.024-.356-.095"
            fill="#F3F1EC"
            fillRule="nonzero"
          />

          <Path
            d="M12.032 18.074l-.23-.583.346.11c.124.088.3.139.238.352-.083.142-.223.118-.354.12"
            fill="#E4C371"
            fillRule="nonzero"
          />

          <Path
            d="M12.387 17.953c-.08-.117-.16-.233-.238-.351.288-.055.309.16.355.35l-.058.002-.06-.001z"
            fill="#D0B266"
            fillRule="nonzero"
          />

          <Path
            d="M13.688 16.891l-.947-.233-.249-.009-.07-.187c.006-.109.046-.197.155-.24.333.015.67-.013.997.08l.115.113v.476"
            fill="#E3C372"
            fillRule="nonzero"
          />

          <Path
            d="M12.487 16.537l.005.112c-.143-.021-.24.069-.344.14-.204.043-.085.229-.149.335l-.188-.111c-.142-.042-.289-.076-.366-.225l-.12-.24c.1-.136.255-.147.404-.174.28-.066.541-.058.758.163"
            fill="#F1F0EE"
            fillRule="nonzero"
          />

          <Path
            d="M13.574 16.302h-1.05l.04-.207.079-.022c.303.098.613.135.93.115v.114z"
            fill="#D4B668"
            fillRule="nonzero"
          />

          <Path
            d="M10.497 16.306c.137.02.242.07.233.233-.048.092-.13.141-.22.183l-.22.034-.145-.039-.105-.104-.018-.074.246-.226c.077.004.157.13.23-.007"
            fill="#BAB5A9"
            fillRule="nonzero"
          />

          <Path
            d="M10.027 16.774c-.109-.043-.113-.125-.082-.221l.077-.013-.002-.004.062.121-.005.069-.05.048z"
            fill="#CECAB9"
            fillRule="nonzero"
          />

          <Path
            d="M10.85 15.594c-.001.117-.004.233-.007.351l-.1.005c-.151-.005-.245-.073-.245-.237l.353-.119"
            fill="#C3BFB3"
            fillRule="nonzero"
          />

          <Path
            d="M10.262 17.484l-.23-.235.107-.344c.06-.078.14-.103.233-.1l.161.075.071.165c.012.125-.004.24-.113.324l-.229.115z"
            fill="#9B978A"
            fillRule="nonzero"
          />

          <Path
            d="M10.024 17.25c-.018.16.063.346-.113.468-.065-.181-.275-.397.113-.468"
            fill="#80755E"
            fillRule="nonzero"
          />

          <Path
            d="M10.371 16.891l-.232.014-.112-.13-.005.003.238-.057c.093.021.162.056.111.17"
            fill="#C5BFAF"
            fillRule="nonzero"
          />

          <Path
            d="M10.515 16.992l.012-.084.115-.072c.13-.003.222.074.31.154l.143.356-.243.126-.058.008-.058-.006c-.148-.127-.122-.333-.221-.482"
            fill="#EDECEC"
            fillRule="nonzero"
          />

          <Path
            d="M10.515 16.992c.157.123.24.279.22.482l-.115.015-.127-.12.022-.377"
            fill="#B4AFA1"
            fillRule="nonzero"
          />

          <Path
            d="M10.15 17.95c.008-.16-.06-.341.111-.463.06.178.28.395-.112.464"
            fill="#918A78"
            fillRule="nonzero"
          />

          <Path
            d="M12.625 15.597l.116.231-.098.245-.048-.005c-.11.034-.172-.02-.212-.115l.107-.35.135-.006z"
            fill="#E3D5A5"
            fillRule="nonzero"
          />

          <Path
            d="M12.382 15.954l.212.114-.069.234-.038.235c-.229.037-.459-.188-.688.003-.133-.128-.406.018-.474-.24l.235-.35.35-.118c.123.173.308.105.472.122"
            fill="#FBFCFE"
            fillRule="nonzero"
          />

          <Path
            d="M10.953 16.99c-.134.039-.231-.048-.34-.1-.072-.065-.117-.144-.104-.247.047-.091.132-.102.222-.103h.122l.1.45"
            fill="#D2D0CC"
            fillRule="nonzero"
          />

          <Path
            d="M11.326 16.3l.474.24-.475.008-.252-.008.253-.24z"
            fill="#D8D8D7"
            fillRule="nonzero"
          />

          <Path
            d="M11.202 17.391c.125.008.274-.077.356.094l-.227.01-.124-.003-.005-.1z"
            fill="#D2B671"
            fillRule="nonzero"
          />

          <Path
            d="M11.446 16.788l.366.225c-.157-.02-.441.17-.366-.225"
            fill="#CCCAC7"
            fillRule="nonzero"
          />

          <Path
            d="M10.508 16.643c.091.06.105.15.104.248l-.086.017-.155-.017-.109-.123-.039-.074.04-.037.245-.014z"
            fill="#979386"
            fillRule="nonzero"
          />

          <Path
            d="M10.262 16.657v.111l-.24.01.021-.135.05-.027.04.041z"
            fill="#989387"
            fillRule="nonzero"
          />

          <Path
            d="M10.133 16.657l-.089-.013-.024-.108z"
            fill="#5C584A"
            fillRule="nonzero"
          />
        </G>
      </G>
    </Svg>
  );
}
