import Svg, { Path } from "react-native-svg";

interface BottomNavI {
  color: string;
  size: number;
}

export function PortfolioIcon({ color, size }: BottomNavI): JSX.Element {
  return (
    <Svg width={size} height={size} viewBox="0 0 24 24" fill="none">
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.5 6.75C5.5 6.05964 6.05964 5.5 6.75 5.5H17.0753V4H6.75C5.23122 4 4 5.23122 4 6.75V10.8486V10.8979V17.0951C4 18.6138 5.23122 19.8451 6.75 19.8451H18.1567C19.6755 19.8451 20.9067 18.6138 20.9067 17.0951V10.8486C20.9067 9.32981 19.6755 8.09859 18.1567 8.09859H6.75C6.29989 8.09859 5.87503 8.20673 5.5 8.39844V6.75ZM5.5 10.8486V10.8979V17.0951C5.5 17.7854 6.05964 18.3451 6.75 18.3451H18.1567C18.847 18.3451 19.4067 17.7854 19.4067 17.0951V10.8486C19.4067 10.1582 18.847 9.59859 18.1567 9.59859H6.75C6.05965 9.59859 5.5 10.1582 5.5 10.8486ZM17.0754 13.9718C17.0754 14.5377 16.6155 14.9965 16.0483 14.9965C15.481 14.9965 15.0212 14.5377 15.0212 13.9718C15.0212 13.4059 15.481 12.9472 16.0483 12.9472C16.6155 12.9472 17.0754 13.4059 17.0754 13.9718Z"
        fill={color}
      />
    </Svg>
  );
}

export function DEXIcon({ color, size }: BottomNavI): JSX.Element {
  return (
    <Svg width={size} height={size} viewBox="0 0 24 24" fill="none">
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.1859 16.9497L15.6693 20.4696L16.7305 21.5298L21.5305 16.7252L22.0605 16.1946L21.53 15.6646L16.73 10.8691L15.6698 11.9303L19.1926 15.4497L10.1999 15.4497L10.1999 16.9497L19.1859 16.9497ZM4.80719 8.54971L13.7999 8.54971L13.7999 7.04971L4.8139 7.04971L8.33047 3.52979L7.2693 2.46964L2.46931 7.2742L1.93923 7.80478L2.46981 8.33486L7.26981 13.1303L8.32996 12.0691L4.80719 8.54971Z"
        fill={color}
      />
    </Svg>
  );
}

export function LoansIcon({ color, size }: BottomNavI): JSX.Element {
  return (
    <Svg width={size} height={size} viewBox="0 0 24 24" fill="none">
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.15564 12C2.15564 8.20542 5.23175 5.1293 9.02633 5.1293C12.8209 5.1293 15.897 8.20542 15.897 12C15.897 15.7946 12.8209 18.8707 9.02633 18.8707C5.23175 18.8707 2.15564 15.7946 2.15564 12ZM9.02633 3.6293C4.40333 3.6293 0.65564 7.37699 0.65564 12C0.65564 16.623 4.40333 20.3707 9.02633 20.3707C13.6493 20.3707 17.397 16.623 17.397 12C17.397 7.37699 13.6493 3.6293 9.02633 3.6293ZM14.9736 20.3707C14.8012 20.3707 14.6299 20.3655 14.46 20.3552C15.1668 19.8946 15.8116 19.3467 16.379 18.7268C19.4995 18.0782 21.8443 15.3129 21.8443 12C21.8443 8.68705 19.4995 5.92175 16.379 5.27315C15.8116 4.6533 15.1668 4.10542 14.46 3.6448C14.6299 3.63452 14.8012 3.6293 14.9736 3.6293C19.5966 3.6293 23.3443 7.37699 23.3443 12C23.3443 16.623 19.5966 20.3707 14.9736 20.3707Z"
        fill={color}
      />
    </Svg>
  );
}

export function AuctionsIcon({ color, size }: BottomNavI): JSX.Element {
  return (
    <Svg width={size} height={size} viewBox="0 0 24 24" fill="none">
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.4208 21.5602L12.9378 13.0772L10.3897 15.6329C9.7973 16.2244 8.99447 16.5564 8.15756 16.5564C7.32065 16.5564 6.51779 16.2244 5.92544 15.6329L3.76892 13.4764C3.17838 12.8838 2.84692 12.0809 2.84692 11.2443C2.84692 10.4077 3.17835 9.60511 3.76892 9.01246L9.84329 2.92351C10.4357 2.33234 11.2385 2 12.0754 2C12.9123 2 13.7152 2.33234 14.3075 2.92351L16.4641 5.08003C17.0544 5.67294 17.3858 6.47554 17.3858 7.31215C17.3858 8.14876 17.0543 8.95133 16.4641 9.54427L13.8474 12.1644L22.3303 20.6474C22.5814 20.8997 22.5814 21.3079 22.3303 21.5602C22.0784 21.8092 21.6728 21.8092 21.4208 21.5602ZM6.83468 14.7129C7.1854 15.0636 7.66156 15.2602 8.15756 15.2593L8.15748 15.2664C8.65233 15.2673 9.12724 15.0704 9.47649 14.72L15.5689 8.63493C15.9191 8.28451 16.1156 7.80927 16.1156 7.31387C16.1156 6.8185 15.9191 6.34355 15.5689 5.99312L13.4124 3.8366C13.062 3.48647 12.5867 3.28961 12.0913 3.28961C11.596 3.28961 11.121 3.48646 10.7706 3.8366L4.67816 9.91454C4.32891 10.2653 4.13295 10.7402 4.13295 11.2353C4.13295 11.7304 4.32891 12.2053 4.67816 12.5563L6.83468 14.7129ZM11.5465 21.9981H2.52648C2.39042 22.0117 2.25639 21.9498 2.15649 21.8273C2.0568 21.7049 2 21.5329 2 21.353C2 21.173 2.0568 21.0013 2.15649 20.8789C2.25639 20.7565 2.39042 20.6945 2.52648 20.7078H11.5465C11.8022 20.733 12 21.0144 12 21.353C12 21.6915 11.8022 21.9729 11.5465 21.9981Z"
        fill={color}
      />
    </Svg>
  );
}
