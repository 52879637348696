import Svg, { Path, SvgProps } from "react-native-svg";

export function dUSDC(props: SvgProps): JSX.Element {
  return (
    <Svg height={32} width={32} viewBox="0 0 32 32" {...props}>
      <Path
        d="M16 0c8.837 0 16 7.163 16 16 0 8.836-7.163 16-16 16S0 24.836 0 16C0 7.163 7.163 0 16 0z"
        fill="#DDEDFF"
      />

      <Path
        d="M13 27.124c0 .377-.294.59-.658.477-4.838-1.543-8.345-6.08-8.345-11.432s3.507-9.888 8.345-11.432c.364-.113.659.1.659.477v.929c0 .25-.195.545-.433.627-3.828 1.405-6.57 5.082-6.57 9.393 0 4.31 2.742 7.988 6.57 9.392a.724.724 0 01.433.628v.941z"
        fill="#2775CA"
      />

      <Path
        d="M16.998 23.667a.503.503 0 01-.502.502h-.998a.503.503 0 01-.502-.502v-1.581c-2.183-.295-3.25-1.519-3.538-3.181a.465.465 0 01.464-.54h1.142c.238 0 .44.17.49.408.213.991.784 1.75 2.534 1.75 1.293 0 2.209-.721 2.209-1.8 0-1.08-.54-1.487-2.435-1.801-2.798-.377-4.122-1.224-4.122-3.42 0-1.694 1.28-3.011 3.263-3.294v-1.55c0-.276.226-.502.502-.502h.997c.276 0 .502.226.502.502v1.594c1.613.29 2.635 1.205 2.968 2.723a.464.464 0 01-.458.558H18.46a.508.508 0 01-.483-.37c-.282-.965-.973-1.38-2.171-1.38-1.324 0-2.008.64-2.008 1.537 0 .948.39 1.424 2.422 1.72 2.748.376 4.166 1.16 4.166 3.501 0 1.776-1.317 3.213-3.382 3.545v1.581h-.006z"
        fill="#2775CA"
      />

      <Path
        d="M19.658 27.601c-.364.114-.659-.1-.659-.476v-.929c0-.276.17-.533.433-.627 3.828-1.406 6.57-5.083 6.57-9.393 0-4.31-2.742-7.987-6.57-9.393A.725.725 0 0119 6.155v-.928c0-.376.295-.596.66-.477 4.837 1.544 8.344 6.08 8.344 11.432-.006 5.34-3.507 9.87-8.345 11.42z"
        fill="#2775CA"
      />
    </Svg>
  );
}
